import {ProgressBarLabel} from '../../dashboard/components/ProgressBarLabel/ProgressBarLabel';
import {Avatar, Box, Progress} from '@mantine/core';
import {Column} from 'components/table/DynamicTable';
import React from 'react';
import {Color} from 'enums/common/Color';
import {CourseUser} from 'types/users/users';
import {TFunction} from 'i18next';

export const getColumns = (t: TFunction): Column<CourseUser>[] => [
  {
    slug: 'name',
    title: {name: t('assignCourse.person'), sortable: true},
    key: 'name',
    render: user => (
      <Box style={{display: 'flex', alignItems: 'center', gap: '.5rem'}}>
        <Avatar
          src={user.avatar}
          size="md"
          radius="lg"
          alt="User profile image"
        />
        <span>{user.name}</span>
      </Box>
    ),
  },
  {
    slug: 'departments',
    title: {name: t('assignCourse.team'), sortable: true},
    key: 'departments',
    render: user => user.department || '-',
  },
  {
    slug: 'status',
    title: {name: t('assignCourse.status'), sortable: true},
    key: 'status',
    render: user => (
      <span>
        {
          t(`assignCourse.courseStatus.${user.status}`, {
            defaultValue: '-',
          }) as string
        }
      </span>
    ),
  },
  {
    slug: 'progress',
    title: {name: t('assignCourse.progress'), sortable: false},
    key: 'progress',
    render: user => (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{display: 'flex', justifyContent: 'end'}}>
          <ProgressBarLabel>{user.progress}%</ProgressBarLabel>
        </Box>
        <Progress radius={0} value={user.progress} color={Color.GREEN_050} />
      </Box>
    ),
  },
];
