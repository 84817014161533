import {useQuery} from '@tanstack/react-query';

import {getCourseUsers} from '../../courses';

export const useGetCourseUsers = (
  courseId: number,
  departmentId: number | null,
  page: number,
  size: number,
  sortKey: string
) => {
  return useQuery(
    ['courses/users', courseId, departmentId, page, size, sortKey],
    () => getCourseUsers(courseId, departmentId, page, size, sortKey),
    {enabled: !!courseId}
  );
};
