import {Badge, Box} from '@mantine/core';
import React from 'react';
import {Color} from '../../../../enums/common';
import {CourseType} from '../../../../types/courses/courses';
import {AudioIcon} from '../../../Entity/IconComponents/AudioIcon';
import {VideoIcon} from '../../../Entity/IconComponents/VideoIcon';

export type BadgeSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const getCourseTypeBadge = (
  courseType: CourseType | undefined,
  size: BadgeSize,
  iconHeight?: number,
  iconWidth?: number
) => {
  switch (courseType) {
    case CourseType.VIDEO:
      return (
        <Badge
          rightSection={<VideoIcon width={iconWidth} height={iconHeight} />}
          size={size}
          style={{
            background: '#E0F3F9',
            color: Color.DARK,
            textTransform: 'none',
          }}
        >
          Video
        </Badge>
      );
    case CourseType.EXERCISE:
      return (
        <Badge
          rightSection={<AudioIcon width={iconWidth} height={iconHeight} />}
          size={size}
          style={{
            background: '#FBE2E3',
            color: Color.DARK,
            textTransform: 'none',
          }}
        >
          Audio
        </Badge>
      );
    case CourseType.BOTH:
      return (
        <Box style={{display: 'flex', gap: '5px'}}>
          <Badge
            rightSection={<VideoIcon width={iconWidth} height={iconHeight} />}
            size={size}
            style={{
              background: '#E0F3F9',
              color: Color.DARK,
              textTransform: 'none',
            }}
          >
            Video
          </Badge>
          <Badge
            rightSection={<AudioIcon width={iconWidth} height={iconHeight} />}
            size={size}
            style={{
              background: '#FBE2E3',
              color: Color.DARK,
              textTransform: 'none',
            }}
          >
            Audio
          </Badge>
        </Box>
      );
    default:
      return;
  }
};
