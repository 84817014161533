import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

// Types
import {Color} from 'enums/common';
import {ChevronRight} from 'tabler-icons-react';
import {AssignedCourseSummary} from 'types/users/users';

// Components
import {Button, Space, Text} from '@mantine/core';
import {EntityTileGrid} from 'components/Grids';
import {SummaryEntityCard} from '../../../Entity/SummaryEntityCard/SummaryEntityCard';
import {getCtaTitle} from 'utils/card';

const FlexContainer = styled.div`
  width: 100%;
  color: ${Color.DARK};
`;

const StyledCourseTitle = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: ${Color.DARK};
`;

export interface AllCoursesProps {
  courses: AssignedCourseSummary[];
  topics?: string[];
  hasButton?: boolean;
}

export const AllSummaryCourses: React.FC<AllCoursesProps> = ({
  courses,
  hasButton = false,
}) => {
  const {t} = useTranslation('Dashboard');
  const navigate = useNavigate();

  return (
    <>
      <StyledHeader>
        <StyledCourseTitle>
          {t('courseCards.continueCourses')}...
        </StyledCourseTitle>
        <Button
          onClick={() => navigate('/courses')}
          variant="subtle"
          size="xs"
          rightIcon={<ChevronRight size={20} />}
          styles={{
            root: {
              color: Color.GREEN_050,
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.3px',
              textDecorationLine: 'underline',
              textDecorationStyle: 'solid',
              textDecorationSkipInk: 'none',
              textDecorationThickness: 'auto',
              textUnderlineOffset: 'auto',
              textUnderlinePosition: 'from-font',
              backgroundColor: 'transparent',
              padding: 0,
              '&:hover': {
                background: 'none',
                textDecorationLine: 'underline',
              },
            },
          }}
        >
          {t('courseCards.seeAllCourses')}
        </Button>
      </StyledHeader>

      {/* Display courses */}
      <FlexContainer style={{width: '100%'}}>
        <EntityTileGrid>
          {courses
            .sort((a, b) => a.completionPercentage - b.completionPercentage)
            .map(course => (
              <SummaryEntityCard
                key={course.topic}
                {...course}
                buttonLabel={getCtaTitle(course.completionPercentage)}
                hasButton={hasButton}
              />
            ))}
        </EntityTileGrid>
      </FlexContainer>
      <Space h={40} />
    </>
  );
};
