import {Button, Group, Select} from '@mantine/core';
import React from 'react';
import {ChevronLeft, ChevronRight} from 'tabler-icons-react';
import {PageNumberSeparatorSpan} from '../../pages/dashboard/components/PageNumberSeparatorSpan/PageNumberSeparatorSpan';
import {PageNumberSpan} from '../../pages/dashboard/components/PageNumberSpan/PageNumberSpan';
import {RowsPerPageSpan} from '../../pages/dashboard/components/RowPerPageSpan/RowsPerPageSpan';
import {DynamicPaginationProps} from './interfaces/DynamicPaginationProps';

export const DynamicPagination = ({
  page,
  totalPages,
  size,
  setSize,
  setPage,
  paginationOptions,
}: DynamicPaginationProps) => {
  return (
    <Group position="apart" align="center">
      <Group spacing="xs">
        <RowsPerPageSpan>Rows per page:</RowsPerPageSpan>
        <Select
          size="xs"
          data={paginationOptions}
          value={size.toString()}
          onChange={size => setSize(size ? +size : 15)}
          styles={{
            input: {
              borderRadius: 0,
            },
          }}
        />
      </Group>

      <Group spacing="xs" align="center">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '.5rem',
          }}
        >
          <PageNumberSpan>{page}</PageNumberSpan>
          <PageNumberSeparatorSpan>of</PageNumberSeparatorSpan>
          <PageNumberSpan>{totalPages}</PageNumberSpan>
        </div>
        <Button
          variant="transparent"
          size="xs"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          <ChevronLeft size={16} />
        </Button>
        <Button
          variant="transparent"
          size="xs"
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
        >
          <ChevronRight size={16} />
        </Button>
      </Group>
    </Group>
  );
};
