import React, {FC, ReactNode} from 'react';
import {Box, Button, Card, Group, Text} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {Color} from 'enums/common';
import {ArrowRight} from 'tabler-icons-react';

interface MetricCardProps {
  title: string;
  icon?: ReactNode;
  metricValue?: string | number | ReactNode;
  children?: ReactNode;
  backgroundColor?: string;
  renderNavigationLink?: boolean;
}

const Title = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${Color.DARK};
`;

const Value = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: ${Color.DARK};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.DARK};
`;

export const MetricCard: FC<MetricCardProps> = ({
  icon,
  title,
  metricValue,
  children,
  backgroundColor = '',
  renderNavigationLink = false,
}) => {
  const navigate = useNavigate();

  const {t} = useTranslation('Dashboard');

  return (
    <Card
      withBorder
      style={{
        width: '100%',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        boxShadow: '0px 2px 4px 0px rgba(77, 60, 130, 0.20)',
        ...(backgroundColor && {backgroundColor}),
      }}
    >
      <Group
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Title>{title}</Title>
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </Group>
      {metricValue && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Value>{metricValue}</Value>
          {renderNavigationLink && (
            <Button
              onClick={() => navigate('/courses')}
              variant="subtle"
              size="xs"
              rightIcon={<ArrowRight size={16} />}
              styles={{
                root: {
                  color: Color.GREEN_050,
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '0.3px',
                  textDecorationLine: 'underline',
                  textDecorationStyle: 'solid',
                  textDecorationSkipInk: 'none',
                  textDecorationThickness: 'auto',
                  textUnderlineOffset: 'auto',
                  textUnderlinePosition: 'from-font',
                  backgroundColor: 'transparent',
                  padding: 0,
                  '&:hover': {
                    background: 'none',
                    textDecorationLine: 'underline',
                  },
                },
              }}
            >
              {t('goToCourses')}
            </Button>
          )}
        </Box>
      )}
      {children}
    </Card>
  );
};
