import {useQuery} from '@tanstack/react-query';
import {getUnassignedCourses} from '../../courses/courses';

export const useGetUnassignedCourses = (
  page: number,
  size: number,
  categories: number[],
  type: number | null
) => {
  return useQuery(['unassignedCourses', page, size, categories, type], () =>
    getUnassignedCourses(page, size, categories, type)
  );
};
