import {Card, Image, Progress, Text} from '@mantine/core';

// Components
import {Button} from 'components/button';
import {getCourseTypeBadge} from 'components/courses/common/CourseTypeBadgeExtractor/getCourseTypeBadge';

// Style
import {Color} from 'enums/common';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {CourseType} from '../../types/courses/courses';

// Styled components
const StyledTitle = styled.span<{
  fontSize?: string;
  fontWeight?: number;
  marginTop?: string;
}>`
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: ${({fontSize = '14px'}) => fontSize};
  font-weight: ${({fontWeight = 700}) => fontWeight};
  color: ${Color.DARK};
  padding: 0;
  margin: ${({marginTop = '0px'}) => marginTop} 0 0;
  width: 100%;
  text-transform: capitalize;
`;

const CourseDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 14px;
  width: 100%;
`;

const CourseDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledPercentage = styled.div<{marginLeft?: string}>`
  flex: 1;
  margin-left: ${({marginLeft = '14px'}) => marginLeft};
  margin-right: 5px;
`;

const StyledPercentageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: end;
`;
const StyledPercentageText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};

  &::first-letter {
    text-transform: capitalize;
  }
`;
const StyledText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  flex: 1;

  &::first-letter {
    text-transform: capitalize;
  }
`;

const CourseTypeBadgeWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

type EntityCardProps = {
  to: string;
  coverImage?: string;
  title: string;
  subtitle?: string;
  learningPercent?: number | null;
  courseLevelName?: string;
  totalChaptersLabel?: string;
  buttonLabel?: string;
  courseLevelLabel?: string;
  assignedLabel?: string;
  timesCompleted?: string;
  hideProgress?: boolean;
  onClick?: () => void;
  courseType?: CourseType;
};

export const EntityCard = ({
  to,
  coverImage,
  title,
  subtitle = '',
  buttonLabel = '',
  learningPercent = null,
  totalChaptersLabel = '',
  courseLevelLabel = '',
  assignedLabel = '',
  timesCompleted = '',
  hideProgress = false,
  onClick,
  courseType,
}: EntityCardProps) => {
  return (
    <Card
      shadow="sm"
      radius={4}
      p="lg"
      style={{
        height: 360,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '35px',
      }}
      component={Link}
      to={to}
      onClick={onClick}
    >
      <Card.Section>
        <Image
          src={coverImage ?? '/images/default.jpeg'}
          height={130}
          alt="no image"
        />
        <StyledTitle marginTop="5px" fontSize="10px" fontWeight={600}>
          {subtitle}
        </StyledTitle>
        <StyledTitle marginTop="-10px">{title}</StyledTitle>
      </Card.Section>
      <Card.Section>
        <CourseDetailsWrapper>
          <CourseTypeBadgeWrapper>
            {getCourseTypeBadge(courseType, 'lg')}
          </CourseTypeBadgeWrapper>
          {timesCompleted && (
            <CourseDetail>
              <Image
                width={13}
                height={13}
                style={{marginRight: '5px'}}
                src="/images/done.svg"
              />
              <StyledText>{timesCompleted}</StyledText>
            </CourseDetail>
          )}

          {totalChaptersLabel && !hideProgress && (
            <CourseDetail>
              <Image
                width={13}
                height={13}
                style={{marginRight: '5px'}}
                src="/images/chaptersleft.svg"
              />
              <StyledText>{totalChaptersLabel}</StyledText>
            </CourseDetail>
          )}
          {courseLevelLabel && (
            <CourseDetail>
              <Image
                width={13}
                height={13}
                style={{marginRight: '5px'}}
                src="/images/graduation.svg"
              />
              <StyledText>{courseLevelLabel}</StyledText>
            </CourseDetail>
          )}
          {assignedLabel && (
            <CourseDetail>
              <Image
                width={13}
                height={13}
                style={{marginRight: '5px'}}
                src="/images/pen.svg"
              />
              <StyledText>{assignedLabel}</StyledText>
            </CourseDetail>
          )}
        </CourseDetailsWrapper>
        <FooterWrapper>
          {buttonLabel && (
            <Button style={{width: '100%', borderRadius: 0}}>
              {buttonLabel}
            </Button>
          )}
          {learningPercent !== null && !hideProgress && (
            <StyledPercentageWrapper>
              <StyledPercentage marginLeft={!buttonLabel ? '0px' : '14px'}>
                <Progress
                  size={12}
                  radius={0}
                  sections={[
                    {
                      value: Math.max(learningPercent, 1),
                      color:
                        learningPercent >= 100
                          ? Color.DARK
                          : 'rgb(52,157, 142)',
                    },
                    {
                      value: 100 - learningPercent,
                      color: '#d0d0d0',
                    },
                  ]}
                />
              </StyledPercentage>

              <StyledPercentageText>{learningPercent}%</StyledPercentageText>
            </StyledPercentageWrapper>
          )}
        </FooterWrapper>
      </Card.Section>
    </Card>
  );
};
