import styled from 'styled-components';

export const PointsSpan = styled.div`
  color: #776aa1;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
`;
