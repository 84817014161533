import React from 'react';

interface AudioIconProps {
  width?: number;
  height?: number;
}

export const AudioIcon = ({width = 18, height = 17}: AudioIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{display: 'block', verticalAlign: 'middle'}}
    >
      <path
        d="M10.75 12.5C12.41 12.5 13.75 11.16 13.75 9.5V3.5C13.75 1.84 12.41 0.5 10.75 0.5C9.09 0.5 7.75 1.84 7.75 3.5V9.5C7.75 11.16 9.09 12.5 10.75 12.5ZM9.75 3.5C9.75 2.95 10.2 2.5 10.75 2.5C11.3 2.5 11.75 2.95 11.75 3.5V9.5C11.75 10.05 11.3 10.5 10.75 10.5C10.2 10.5 9.75 10.05 9.75 9.5V3.5ZM15.75 9.5C15.75 12.26 13.51 14.5 10.75 14.5C7.99 14.5 5.75 12.26 5.75 9.5H3.75C3.75 13.03 6.36 15.93 9.75 16.42V19.5H11.75V16.42C15.14 15.93 17.75 13.03 17.75 9.5H15.75Z"
        fill="#26145C"
      />
    </svg>
  );
};
