import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {useGetUnassignedCourses} from 'api/tanstack-hooks/courses';
import {useGetCourseCategories} from '../../../api/tanstack-hooks/courses/useGetCourseCategories';
import {Color} from '../../../enums/common';
import {Badge, Space} from '@mantine/core';
import {EntityTileGrid} from 'components/Grids';
import {EntityCard} from 'components/Entity';
import {CourseType} from '../../../types/courses/courses';
import {getCourseType} from '../common/CourseTypeExtractor/courseTypeExtractor';
import {SubmenuItemProps} from './submenu/SubmenuItemProps';

const FlexContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  color: ${Color.DARK};
`;

const SubMenuWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
`;

const SubMenuButton = styled.button<{isActive: boolean}>`
  background: none;
  border: none;
  font-size: 16px;
  color: ${Color.DARK};
  font-weight: ${({isActive}) => (isActive ? 'bold' : 'normal')};
  position: relative;
  padding: 10px 0;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${({isActive}) => (isActive ? 'green' : 'transparent')};
    transition: all 0.3s ease;
  }
`;

const CustomCategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const TopicsSpanWrapper = styled.span`
  color: #5c4d8a;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.15px;
  margin-top: 0.3rem;
`;

export const AssignWorkersToCourses: React.FC = () => {
  const {t} = useTranslation('Dashboard');
  const location = useLocation();
  const navigate = useNavigate();

  const page = 1;
  const size = 1000;
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const submenuItems = [
    {id: null, label: t('allCourses')},
    {id: CourseType.VIDEO, label: t('videoCourses')},
    {id: CourseType.EXERCISE, label: t('audioPractices')},
  ];

  const allCoursesItem = submenuItems.find(item => item.id === null);
  const [activeItem, setActiveItem] = useState<SubmenuItemProps | undefined>(
    allCoursesItem
  );

  const {data: unassignedCourses} = useGetUnassignedCourses(
    page,
    size,
    selectedCategories,
    activeItem?.id || null
  );
  const {data: categories = []} = useGetCourseCategories();

  const courses = unassignedCourses?.data?.elements || [];

  const updatedSubmenuItems = submenuItems.map(item => {
    if (item.id === activeItem?.id) {
      const courseCount = courses.filter(course => {
        if (item.id === null) return true;
        return course.type?.some(category => category.id === item.id);
      }).length;

      return {
        ...item,
        label: `${item.label} (${courseCount})`,
      };
    }
    return item;
  });

  const getCategoriesFromUrl = useCallback(() => {
    const params = new URLSearchParams(location.search);
    return params.getAll('categories').map(c => parseInt(c));
  }, [location.search]);

  useEffect(() => {
    setSelectedCategories(getCategoriesFromUrl());
  }, [getCategoriesFromUrl]);

  const updateUrlCategories = (categories: number[]) => {
    const params = new URLSearchParams(location.search);
    params.delete('categories');
    categories.forEach(category =>
      params.append('categories', category.toString())
    );
    navigate({search: params.toString()});
  };

  const toggleCategorySelection = (id: number) => {
    const updatedCategories = selectedCategories.includes(id)
      ? selectedCategories.filter(selectedId => selectedId !== id)
      : [...selectedCategories, id];

    setSelectedCategories(updatedCategories);
    updateUrlCategories(updatedCategories);
  };

  return (
    <>
      {unassignedCourses && (
        <>
          <SubMenuWrapper>
            {updatedSubmenuItems.map(item => (
              <SubMenuButton
                key={item.id}
                isActive={activeItem?.id === item.id}
                onClick={() => setActiveItem(item)}
              >
                {item.label}
              </SubMenuButton>
            ))}
          </SubMenuWrapper>

          <CustomCategoriesWrapper>
            <TopicsSpanWrapper>{t('topics')}</TopicsSpanWrapper>
            {categories.map(category => (
              <Badge
                sx={{
                  textTransform: 'none',
                  backgroundColor: selectedCategories.includes(category.id)
                    ? '#E7E7F8'
                    : 'white',
                  color: Color.DARK,
                  cursor: 'pointer',
                  border: selectedCategories.includes(category.id)
                    ? 'none'
                    : '2px solid #DDDDF8',
                  paddingTop: 12,
                  paddingBottom: 12,
                }}
                leftSection={null}
                size="lg"
                key={category.id}
                onClick={() => toggleCategorySelection(category.id)}
              >
                {category.name}
              </Badge>
            ))}
          </CustomCategoriesWrapper>

          <FlexContainer>
            <EntityTileGrid>
              {courses.map(course => (
                <EntityCard
                  courseType={getCourseType(course.type)}
                  to={`/manager/course/${course.courseId}/details`}
                  key={course.courseId}
                  coverImage={course.coverImage}
                  title={course.courseName}
                  subtitle={course?.courseSubtitle}
                  totalChaptersLabel={
                    course.totalChaptersNum === 1
                      ? t('courseCards.chapterLeft', {
                          chapters: course.totalChaptersNum,
                        })
                      : t('courseCards.chaptersLeft', {
                          chapters: course.totalChaptersNum,
                        })
                  }
                  courseLevelLabel={t(`courseCards.${course.level.name}`)}
                  assignedLabel={t('courseCards.assigned')}
                  buttonLabel={t('table.assignWorkers')}
                  learningPercent={course.learningPercent}
                />
              ))}
            </EntityTileGrid>
            <Space h={60} />
          </FlexContainer>
        </>
      )}
    </>
  );
};
