import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {ArrowRight, Check, X} from 'tabler-icons-react';

import {Button, Group, Image, Radio} from '@mantine/core';

import {Answer, Quiz} from 'types/courses';

import {Color} from 'enums/common';
import {DarkButtonStyle} from 'styles/buttons';

import {RichTextContent} from 'components/RichText';

const QuizContainer = styled.div`
  background-color: ${Color.WHITE};
  border-radius: 5px;
  padding: 20px 30px;
`;

const QuizHeader = styled.div`
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Color.GRAY};
  margin-bottom: 10px;
`;

const QuizFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const QuizDescription = styled.div`
  margin: 20px 0 20px 0;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK_LIGHTER};
  width: 100%;
`;

const QuizQuestion = styled.h2`
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CorrectAnswerContainer = styled.div`
  padding: 10px 20px;
  background-color: ${Color.LIGHT_GREEN};
`;

const IncorrectAnswerContainer = styled.div`
  padding: 10px 20px;
  background-color: ${Color.ORANGE};
`;

const AnswerDescription = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
`;

interface CourseQuizProps {
  quiz: Quiz;
  callback: ({isQuiz}: {isQuiz?: boolean | undefined}) => void;
}

export const CourseQuiz: React.FC<CourseQuizProps> = ({quiz, callback}) => {
  const {t} = useTranslation('Courses');

  const [quizIndex, setQuizIndex] = useState(-1);
  const [showAnswerFeedback, setShowAnswerFeedback] = useState(false);
  const [hasAnswerBeenChecked, setHasAnswerBeenChecked] = useState(false);

  const [selectedOption, setSelectedOption] = useState<Answer>();
  const currentQuestion = quiz.quizDetails[quizIndex];

  const QUIZ_NOT_STARTED = quizIndex === -1;

  const quizHeaderText = QUIZ_NOT_STARTED
    ? t('courseDetails.quiz')
    : t('courseDetails.selectAnswer');

  const quizProgressText = QUIZ_NOT_STARTED
    ? ''
    : `${quizIndex + 1}/${quiz.quizDetails.length}`;

  const handleShowAnswerFeedback = () => {
    setShowAnswerFeedback(true);
    setHasAnswerBeenChecked(true);
  };

  const incrementQuizIndex = () => {
    if (quizIndex < quiz.quizDetails.length - 1) {
      setQuizIndex(quizIndex + 1);
    }
  };

  const advanceQuiz = (answer: Answer | undefined) => {
    if (answer?.isCorrect) {
      incrementQuizIndex();
      setSelectedOption(undefined);
    } else {
      setShowAnswerFeedback(true);
    }
  };

  const handleSetSelectedOption = (option: Answer) => {
    setSelectedOption(option);
    setShowAnswerFeedback(false);
    setHasAnswerBeenChecked(false);
  };

  const completeCourse = (answer: Answer | undefined) => {
    if (answer?.isCorrect) {
      callback({isQuiz: true});
    } else {
      setShowAnswerFeedback(true);
    }
  };

  return (
    <>
      <QuizContainer>
        <QuizHeader>
          <div>{quizHeaderText}</div>
          <div>{quizProgressText}</div>
        </QuizHeader>
        {QUIZ_NOT_STARTED ? (
          <>
            <Image
              src="/images/start-quiz.png"
              alt="future-ready-logo"
              width={85}
            />
            <QuizDescription>
              <RichTextContent htmlContent={quiz.introduction} />
            </QuizDescription>
            <Button
              style={DarkButtonStyle}
              rightIcon={<ArrowRight />}
              onClick={incrementQuizIndex}
            >
              {t('courseDetails.startQuiz')}
            </Button>
          </>
        ) : (
          <>
            <QuizQuestion>{currentQuestion.question}</QuizQuestion>
            <OptionContainer>
              {currentQuestion.answers.map((answer, index) => (
                <Fragment key={index}>
                  <Radio
                    key={index}
                    value={answer.answer}
                    label={answer.answer}
                    checked={selectedOption === answer}
                    onChange={() => handleSetSelectedOption(answer)}
                  />
                  {showAnswerFeedback && selectedOption === answer && (
                    <>
                      {answer.isCorrect ? (
                        <CorrectAnswerContainer key={index}>
                          <Group>
                            <Check />
                            <div>{t('quiz.correctAnswer')}</div>
                          </Group>
                          {answer.description && (
                            <AnswerDescription>
                              {answer.description}
                            </AnswerDescription>
                          )}
                        </CorrectAnswerContainer>
                      ) : (
                        <IncorrectAnswerContainer key={index}>
                          <Group>
                            <X />
                            <div>{t('quiz.incorrectAnswer')}</div>
                          </Group>
                          {answer.description && (
                            <AnswerDescription>
                              {answer.description}
                            </AnswerDescription>
                          )}
                        </IncorrectAnswerContainer>
                      )}
                    </>
                  )}
                </Fragment>
              ))}
            </OptionContainer>
            {selectedOption && (
              <QuizFooter>
                {quizIndex < quiz.quizDetails.length - 1 ? (
                  <>
                    {selectedOption?.isCorrect && hasAnswerBeenChecked ? (
                      <Button
                        style={DarkButtonStyle}
                        onClick={() => advanceQuiz(selectedOption)}
                      >
                        {t('quiz.nextPage')}
                      </Button>
                    ) : (
                      <Button
                        style={DarkButtonStyle}
                        onClick={handleShowAnswerFeedback}
                      >
                        {t('quiz.checkAnswer')}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {selectedOption?.isCorrect && hasAnswerBeenChecked ? (
                      <Button
                        style={DarkButtonStyle}
                        onClick={() => completeCourse(selectedOption)}
                      >
                        {t('quiz.completeQuiz')}
                      </Button>
                    ) : (
                      <Button
                        style={DarkButtonStyle}
                        onClick={handleShowAnswerFeedback}
                      >
                        {t('quiz.checkAnswer')}
                      </Button>
                    )}
                  </>
                )}
              </QuizFooter>
            )}
          </>
        )}
      </QuizContainer>
    </>
  );
};
