import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Microphone, X, PhoneOff, ThumbUp, ThumbDown} from 'tabler-icons-react';
import styled from 'styled-components';
import {
  Alert,
  Anchor,
  Button,
  Group,
  Loader,
  Select,
  ThemeIcon,
  Switch,
} from '@mantine/core';
import {AlertCircle} from 'tabler-icons-react';

import {useChatBotCommunicationStore} from 'store/ChatBotCommunication';
import {useChatBotV3Store} from 'store/ChatBotV3store';
import useAudioPlayerStore from 'store/AudioPlayerStore';
import useNetworkStatus from 'hooks/useNetworkStatus';

import {Text} from 'components/common';
import MessageGroup from './MessageGroup';
import {ChatbotV3Typing} from './ChatbotV3Typing';
import {AudioPlayer} from 'containers/player';

import {Exercise} from 'types/chapters/chapters';
import {Color} from 'enums/common';

// Styled components
const Container = styled.div`
  box-shadow: 0 2px 4px 0 #4d3c8233;
`;

// Styled component for Switch Container
const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e6e6ea;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 550px;
  padding: 0 32px 0 32px;
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f0f0;
  background-color: ${Color.WHITE};
  z-index: 1;
  padding: 20px 10px;

  & > div {
    flex: 1;
    max-width: 33%;
    box-sizing: border-box;
  }

  & > div:last-child {
    margin-left: auto;
    text-align: right;
  }
`;

export const Protip = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.PURPLE_400};
  height: 32px;
  padding: 0 35px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #e6e6ea;
  background-color: ${Color.GRAY_500};
  gap: 5px;
  padding: 0 10px;
  position: relative;
`;

const Label = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: ${Color.DARK};
  width: max-content;
`;

export const CoversationFeedback = styled.div`
  min-width: 8.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  gap: 10px;
  position: absolute;
  right: 15px;
`;

export const LoaderWrapper = styled.div`
  padding: 0 30px;
  position: absolute;
  right: 1px;
`;

// Audio Worklet code for processing PCM data
const processorCode = `
  class PCMProcessor extends AudioWorkletProcessor {
    constructor() {
      super();
    }

    process(inputs) {
      const input = inputs[0];
      if (input.length > 0) {
        const channelData = input[0];
        const pcmData = new Int16Array(channelData.length);

        // Convert Float32 [-1, 1] to Int16 [-32768, 32767]
        for (let i = 0; i < channelData.length; i++) {
          pcmData[i] = Math.min(1, Math.max(-1, channelData[i])) * 32768;
        }

        // Send PCM data to the main thread
        this.port.postMessage(pcmData);
      }
      return true;
    }
  }

  registerProcessor('pcm-processor', PCMProcessor);
`;

// Props Interface
interface Props {
  exercise: Exercise;
  onUserConversationFeedback: ({value}: {value: boolean}) => void;
}

const ChatBotV3 = ({exercise, onUserConversationFeedback}: Props) => {
  const {t} = useTranslation('Practices');
  const bottomRef = useRef<HTMLDivElement>(null);

  // Store and State
  const {
    responseTime, // This is the selected inactivity timeout in milliseconds
    setResponseTime,
    textToSpeech,
    toggleTextToSpeech,
  } = useChatBotCommunicationStore();
  const {
    setTextMessage,
    conversation,
    sendingMessage,
    endConversation,
    tries,
    userFeedback,
    setEndConversation,
    setPage,
  } = useChatBotV3Store();
  const resetPlayer = useAudioPlayerStore(state => state.resetPlayer);

  const [isRecording, setIsRecording] = useState(false);
  const isRecordingRef = useRef(isRecording);

  const [showAlert, setShowAlert] = useState(false);

  const {isOnline} = useNetworkStatus();

  // WebSocket Ref
  const wsRef = useRef<WebSocket | null>(null);

  // Refs for AudioContext and MediaStream
  const audioContextRef = useRef<AudioContext | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const pcmBufferRef = useRef<Int16Array[]>([]); // Buffer to store PCM data
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref for interval ID

  // Keep isRecordingRef in sync with isRecording state
  useEffect(() => {
    isRecordingRef.current = isRecording;
  }, [isRecording]);

  // Function to clean up recording resources
  const cleanupRecording = () => {
    // Clear the interval that sends data
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      console.log('Send interval cleared.');
    }

    // Close AudioContext and stop stream
    if (audioContextRef.current) {
      audioContextRef.current
        .close()
        .then(() => {
          console.log('AudioContext closed.');
        })
        .catch(error => {
          console.error('Error closing AudioContext:', error);
        });
      audioContextRef.current = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      console.log('MediaStream tracks stopped.');
      streamRef.current = null;
    }

    // Clear PCM buffer
    pcmBufferRef.current = [];
  };

  // Function to start recording
  const startRecording = async (ws: WebSocket) => {
    try {
      setIsRecording(true);
      pcmBufferRef.current = []; // Reset PCM buffer

      const audioContext = new AudioContext({sampleRate: 16000});
      audioContextRef.current = audioContext;
      const stream = await navigator.mediaDevices.getUserMedia({audio: true});
      streamRef.current = stream;
      const source = audioContext.createMediaStreamSource(stream);

      const processorBlob = new Blob([processorCode], {
        type: 'application/javascript',
      });
      const processorUrl = URL.createObjectURL(processorBlob);
      await audioContext.audioWorklet.addModule(processorUrl);

      const pcmProcessor = new AudioWorkletNode(audioContext, 'pcm-processor');
      source.connect(pcmProcessor);
      // Optional: connect to destination to prevent audio glitches
      // pcmProcessor.connect(audioContext.destination);

      pcmProcessor.port.onmessage = event => {
        const pcmData: Int16Array = event.data;
        pcmBufferRef.current.push(pcmData);
        console.log('PCM data received:', pcmData.length);
      };

      // Continuously send data to WebSocket
      intervalRef.current = setInterval(() => {
        if (
          pcmBufferRef.current.length > 0 &&
          ws &&
          ws.readyState === WebSocket.OPEN
        ) {
          const totalLength = pcmBufferRef.current.reduce(
            (acc, chunk) => acc + chunk.length,
            0
          );
          const concatenatedPCM = new Int16Array(totalLength);
          let offset = 0;

          pcmBufferRef.current.forEach(chunk => {
            concatenatedPCM.set(chunk, offset);
            offset += chunk.length;
          });

          ws.send(concatenatedPCM.buffer);
          pcmBufferRef.current = []; // Clear buffer after sending
          console.log('PCM data sent via WebSocket.');
        }
      }, 1000); // Adjust the interval as needed for real-time transmission

      console.log('Recording started');
    } catch (error) {
      console.error('Error during recording:', error);
      setIsRecording(false);
    }

    setTimeout(() => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 100);
  };

  // Function to handle recording
  const recordVoice = async () => {
    console.log('recordVoice called');
    resetPlayer();
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 100);

    if (!isRecording) {
      if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
        // Initialize WebSocket connection
        // const ws = new WebSocket('ws://13.53.79.107:43007/upload');
        const ws = new WebSocket('wss://future-ready-vox.com/upload');
        ws.binaryType = 'arraybuffer';

        ws.onopen = () => {
          console.log('WebSocket connection established.');

          // Send inactivity timeout to server
          const inactivityTimeout = parseInt(responseTime) / 1000; // Convert milliseconds to seconds
          ws.send(JSON.stringify({inactivity_timeout: inactivityTimeout}));
          console.log(`Sent inactivity timeout: ${inactivityTimeout} seconds`);

          // Start recording only after WebSocket connection is established
          startRecording(ws);
        };

        ws.onmessage = event => {
          if (typeof event.data === 'string') {
            try {
              const data = JSON.parse(event.data);
              if (data.status === 'end_of_transcription') {
                console.log('Received end_of_transcription from server.');
                // Acknowledge and close connection
                ws.send(JSON.stringify({confirmation: 'received_end'}));
                ws.close();
              } else if (data.status === 'stopped') {
                console.log('Received stop acknowledgment from server.');
                // Optionally, handle any final UI updates here
              } else if (data.status === 'success') {
                // Handle configuration acknowledgment
                console.log('Configuration updated:', data.updated_parameters);
              } else {
                // Handle transcription data
                const transcription = data;
                setTextMessage(transcription);
              }
            } catch (e) {
              // If not JSON, assume it's transcription text
              const transcription = event.data;
              setTextMessage(transcription);
            }
          }
        };

        ws.onerror = error => {
          console.error('WebSocket error:', error);
        };

        ws.onclose = () => {
          console.log('WebSocket connection closed.');
          // If recording is ongoing, stop it
          if (isRecordingRef.current) {
            console.log('Recording was ongoing, stopping it.');
            setIsRecording(false);

            // Cleanup recording resources
            cleanupRecording();

            // Since the WebSocket is already closed, no need to close it again
            wsRef.current = null;
          }
        };

        wsRef.current = ws;
      } else {
        // WebSocket is already open
        startRecording(wsRef.current);
      }
    } else {
      console.error('Already recording.');
    }
  };

  // Function to stop recording
  const stopRecording = () => {
    console.log('stopRecording called');
    if (isRecording) {
      // Send stop_recording action to server
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        const stopMessage = JSON.stringify({action: 'stop_recording'});
        wsRef.current.send(stopMessage);
        console.log('Sent stop_recording action to server.');
      }

      setIsRecording(false);
      console.log('Recording stopped manually.');

      // Cleanup recording resources
      cleanupRecording();

      // Do not close the WebSocket here; wait for server to send remaining transcriptions and close
      // The connection will be closed in the server's response handling
    }
  };

  // Handle component unmount
  useEffect(() => {
    return () => {
      // Cleanup on component unmount
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close().catch(error => {
          console.error('Error closing AudioContext on unmount:', error);
        });
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  // Scroll to bottom when conversation updates
  useEffect(() => {
    const timer = setTimeout(() => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 100);
    return () => clearTimeout(timer);
  }, [conversation]);

  // Handle network status changes
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isOnline) {
      setShowAlert(true);
      timer = setTimeout(() => setShowAlert(false), 11000);
    } else if (showAlert) {
      timer = setTimeout(() => setShowAlert(false), 10000);
    }
    return () => clearTimeout(timer);
  }, [isOnline, showAlert]);

  return (
    <Container>
      <ConversationContainer>
        <Header>
          <Text fontSize={18} fontWeight={700} color={Color.DARK}>
            {exercise.title}
          </Text>
          {showAlert && (
            <Alert
              icon={<AlertCircle size={16} />}
              title={t('voicebot.connectionLostAlert')}
              color="red"
              withCloseButton
              onClose={() => setShowAlert(false)}
            >
              <Text>{t('voicebot.restartExercise')}</Text>
              <Anchor
                onClick={() => window.location.reload()}
                style={{marginTop: 10, display: 'inline-block'}}
              >
                Restart
              </Anchor>
            </Alert>
          )}

          {textToSpeech && <AudioPlayer />}
          <Text fontSize={12} fontWeight={400} color={Color.DARK}>
            #{tries}
          </Text>
        </Header>

        <MessageGroup conversation={conversation} exercise={exercise} />
        {isRecording && !endConversation && <ChatbotV3Typing sender />}
        {sendingMessage && !endConversation && <ChatbotV3Typing />}
        <div ref={bottomRef} style={{paddingBottom: '20px'}} />
      </ConversationContainer>

      {conversation.length === 0 && (
        <Protip>
          <Group spacing={11}>
            <Microphone />
            <Text fontSize={12} fontWeight={500} color={Color.DARK}>
              {t('voicebot.start_chatting_with_voicebot_3')}
            </Text>
          </Group>
        </Protip>
      )}

      {endConversation && (
        <Protip>
          <Group spacing={11}>
            <ThumbUp />
            <Text fontSize={12} fontWeight={500} color={Color.DARK}>
              {conversation.length > 0 &&
                endConversation &&
                userFeedback !== null &&
                t('voicebot.conversationFeedback')}
              {userFeedback === null &&
                endConversation &&
                t('voicebot.conversationFeedback')}
            </Text>
          </Group>
        </Protip>
      )}

      <Footer>
        <Group style={{position: 'absolute', left: 15}}>
          <Select
            label={<Label>{t('voicebot.wait')}</Label>}
            data={[
              {value: '500', label: '0.5s'},
              {value: '1000', label: '1s'},
              {value: '2000', label: '2s'},
              {value: '3000', label: '3s'},
              {value: '4000', label: '4s'},
              {value: '5000', label: '5s'},
            ]}
            value={responseTime}
            style={{width: 80}}
            disabled={isRecording || sendingMessage || endConversation}
            onChange={value => setResponseTime(value || '')}
          />
          <SwitchContainer>
            <Label>{t('voicebot.voice')}</Label>
            <Switch
              size="lg"
              color="cyan"
              labelPosition="right"
              checked={textToSpeech}
              onChange={toggleTextToSpeech}
              onLabel="ON"
              offLabel="OFF"
            />
          </SwitchContainer>
        </Group>

        {/* Microphone Button */}
        <ThemeIcon
          onClick={isRecording ? stopRecording : recordVoice}
          size={56}
          radius="xl"
          color={
            endConversation
              ? Color.GRAY_250
              : isRecording
              ? Color.RED_800
              : Color.GREEN_050
          }
          style={{
            cursor: endConversation ? 'not-allowed' : 'pointer',
          }}
        >
          {isRecording ? (
            <X
              width={40}
              height={40}
              style={{
                cursor: 'pointer',
              }}
            />
          ) : (
            <Microphone
              style={{
                cursor: endConversation ? 'not-allowed' : 'pointer',
              }}
            />
          )}
        </ThemeIcon>

        {conversation.length > 0 && !endConversation && (
          <Button
            onClick={() => {
              // Send stop_recording action to server
              if (
                wsRef.current &&
                wsRef.current.readyState === WebSocket.OPEN
              ) {
                const stopMessage = JSON.stringify({action: 'stop_recording'});
                wsRef.current.send(stopMessage);
                console.log('Sent stop_recording action to server.');
              }
              setEndConversation(true);
            }}
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontWeight: 700,
              color: Color.WHITE,
              backgroundColor: '#f90000',
              borderRadius: 0,
              position: 'absolute',
              right: 15,
              opacity: isRecording || sendingMessage ? '50%' : '100%',
            }}
            leftIcon={<PhoneOff />}
            disabled={isRecording || sendingMessage}
          >
            {t('voicebot.endConversation')}
          </Button>
        )}

        {userFeedback === null && endConversation && (
          <CoversationFeedback>
            <span>
              <ThumbUp
                onClick={() => onUserConversationFeedback({value: true})}
                style={{cursor: 'pointer'}}
                size={30}
                color={Color.MID_GREEN}
              />
            </span>
            <span>
              <ThumbDown
                onClick={() => onUserConversationFeedback({value: false})}
                style={{cursor: 'pointer'}}
                size={30}
                color={Color.DARK_GREEN}
              />
            </span>
          </CoversationFeedback>
        )}

        {conversation.length === 0 && endConversation && userFeedback === null && (
          <LoaderWrapper>
            <Loader
              variant="oval"
              color={Color.DARK}
              size="xl"
              height={30}
              style={{alignSelf: 'center'}}
            />
          </LoaderWrapper>
        )}

        {conversation.length > 0 && endConversation && userFeedback !== null && (
          <Button
            onClick={() => setPage(4)}
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontWeight: 700,
              color: Color.WHITE,
              backgroundColor: Color.DARK,
              borderRadius: 0,
              position: 'absolute',
              right: 15,
            }}
            leftIcon={<ThumbUp />}
          >
            {t('voicebot.analyzeConversation')}
          </Button>
        )}
      </Footer>
    </Container>
  );
};

export default ChatBotV3;
