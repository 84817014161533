export enum Color {
  DARK = '#26145C',
  DARK_LIGHTER = '#26145CCC',
  WHITE = '#FFFFFF',
  GRAY = '#E5E5E5',
  BITTER_SWEET = '#F2675A',
  GREEN_050 = '#349D8E',
  ORANGE = '#F36767',
  LIGHT_BLUE = '#776AA1',
  DARK_GRAY = '#BEBEBE',
  LIGHT_GREEN = '#EAFBE3',
  GREEN_200 = '#CCE9E5',
  DARK_GREEN = '#8da791',
  MID_GREEN = '#95c59c',
  PURPLE_150 = '#776AA1',
  PURPLE_200 = '#E7E7F8',
  PURPLE_400 = '#DDDDF8',
  PURPLE_500 = '#F6F4FC',
  PURPLE_TXT = '#5C4D8A',
  BLUE_400 = '#228be6',
  BLUE_300 = '#58C1DE',
  GRAY_50 = '#EEE7FE',
  GRAY_200 = '#94939F',
  GRAY_100 = '#F9F9F9',
  GRAY_250 = '#BDBCC7',
  GRAY_300 = '#ABABAB',
  GRAY_500 = '#F6F6F8',
  ALPHA_DARK_40 = '#26145C66',
  ALPHA_WHITE_56 = '#FFFFFF8F',
  SUCCESS_200 = '#1C5B06',
  SUCCESS_500 = '#E9FBE3',
  RED_900 = '#C92A2A',
  RED_800 = '#E03131',
  RED_100 = '#FFE3E3',
  INDIGO_100 = '#DBE4FF',
  INDIGO_800 = '#3B5BDB',
  ERROR_100 = '#9B160C',
  ERROR_500 = '#FFEAE9',
  INFO_100 = '#0D499D',
  INFO_500 = '#E0F1FF',
  VIOLET_VIOLET4 = '#9289AD',
}
