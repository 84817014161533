import {create} from 'zustand';
import {
  chatBotV3Communication,
  startExercise,
  generateChatFeedback,
  completeExercise,
} from 'api/practices';
import {useChatBotCommunicationStore} from 'store/ChatBotCommunication';
import {StartExerciseInterface} from 'pages/practices/PracticeV3';
import {NavigateFunction} from 'react-router-dom';
import {ChatBotV3State} from './types/ChatBotV3';
import {useAudioPlayerStore} from './AudioPlayerStore';

export const useChatBotV3Store = create<ChatBotV3State>((set, get) => ({
  page: 1,
  setPage: page => set(() => ({page})),
  textMessage: '',
  setTextMessage: message => set(() => ({textMessage: message})),
  conversation: [],
  setConversation: conversation => set(() => ({conversation})),
  sendingMessage: false,
  setSendingMessage: sending => set(() => ({sendingMessage: sending})),
  endConversation: false,
  setEndConversation: end => set(() => ({endConversation: end})),
  feedbackMessage: null,
  setFeedbackMessage: feedback => set(() => ({feedbackMessage: feedback})),
  summary: null,
  setSummary: summary => set(() => ({summary})),
  tries: 1,
  setTries: tries => set(() => ({tries})),
  _conversationId: 0,
  _setConversationId: id => set(() => ({_conversationId: id})),
  conversationIds: [],
  setConversationIds: ids => set(() => ({conversationIds: ids})),
  feedbacksHaveBeenGenerated: false,
  setFeedbacksHaveBeenGenerated: generated =>
    set(() => ({feedbacksHaveBeenGenerated: generated})),
  feedbackLoading: false,
  setFeedbackLoading: loading => set(() => ({feedbackLoading: loading})),
  conversationFeedback: null,
  setConversationFeedback: feedback =>
    set(() => ({conversationFeedback: feedback})),
  retryLoading: false,
  setRetryLoading: loading => set(() => ({retryLoading: loading})),
  userFeedback: null,
  setUserFeedback: feedback => set(() => ({userFeedback: feedback})),

  reset: () =>
    set(() => ({
      page: 1,
      textMessage: '',
      conversation: [],
      sendingMessage: false,
      endConversation: false,
      feedbackMessage: null,
      summary: null,
      tries: 1,
      _conversationId: 0,
      conversationIds: [],
      feedbacksHaveBeenGenerated: false,
      feedbackLoading: false,
      conversationFeedback: null,
      retryLoading: false,
      userFeedback: null,
    })),

  handleChatBotCommunication: async () => {
    const {setAudioSrc} = useAudioPlayerStore.getState();
    const {
      textMessage,
      setSendingMessage,
      setConversation,
      conversation,
      _conversationId,
    } = get();

    if (!textMessage.length || get().page !== 2) return;

    setSendingMessage(true);

    try {
      setConversation([...conversation, {role: 'user', content: textMessage}]);

      const data = await chatBotV3Communication({
        message: textMessage,
        conversationId: _conversationId,
        textToSpeech: useChatBotCommunicationStore.getState().textToSpeech,
      });

      const audioUrl = data.audioUrl as string;
      if (audioUrl) {
        try {
          const audio = new Audio(audioUrl);
          audio.crossOrigin = 'anonymous';

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (document as any).voice = audio;
          audio.play().catch(error => {
            alert(error.message);
            console.error('Error playing sound:', error);
          });
          // This is a trick just to trigger the audio analyzer on the top of the chat
          // So it will play and not be muted and also we can analyze the sound and add the sound bars
          setAudioSrc(audioUrl);
        } catch (error) {
          console.error(error);
        }
      }

      let messages = [...data.conversation];
      const conversationLength = conversation.length;
      if (conversationLength) {
        messages = [...conversation];

        for (let i = conversationLength; i < data.conversation.length; i++) {
          messages.push(data.conversation[i]);
        }
      }

      setConversation(messages);
      set(() => ({textMessage: ''}));

      if (data.endConversation) {
        set(() => ({endConversation: true}));
        return;
      }

      setSendingMessage(false);
    } catch (error) {
      setSendingMessage(false);
    }
  },

  handleStartExercise: async (
    _userExerciseId: number | undefined,
    exerciseTries: null | number = null
  ) => {
    const {
      setSendingMessage,
      _setConversationId,
      setTries,
      setConversation,
      setEndConversation,
      setTextMessage,
      setPage,
      setConversationFeedback,
      setUserFeedback,
      tries,
    } = get();

    if (!_userExerciseId) return;

    setSendingMessage(false);
    const res = await startExercise(+_userExerciseId);
    const data = res.data as unknown as StartExerciseInterface;

    _setConversationId(data.conversationId as unknown as number);
    const exTries = exerciseTries !== null ? exerciseTries : tries;

    setTries(exTries + 1);
    setConversation([]);
    setEndConversation(false);
    setTextMessage('');
    setPage(2);
    setConversationFeedback(null);
    setUserFeedback(null);
  },

  handleRetry: async (userExerciseId: number | undefined) => {
    const {
      setPage,
      setRetryLoading,
      setFeedbacksHaveBeenGenerated,
      setConversationIds,
      setFeedbackMessage,
      setSummary,
      setFeedbackLoading,
      setConversation,
      handleStartExercise,
    } = get();

    setPage(1);
    setRetryLoading(true);
    setFeedbacksHaveBeenGenerated(false);
    setConversationIds([]);
    setFeedbackMessage(null);
    setSummary(null);
    setFeedbackLoading(false);
    setConversation([]);

    const exerciseTries = 0;
    await handleStartExercise(userExerciseId, exerciseTries);
    setRetryLoading(false);
  },

  onSingleConversationFeedback: async ({
    value,
    userExerciseId,
  }: {
    value: boolean;
    userExerciseId: number;
  }) => {
    const {
      _conversationId,
      setFeedbackLoading,
      setConversationFeedback,
      setPage,
    } = get();

    setFeedbackLoading(true);
    setPage(3);

    try {
      const response = await generateChatFeedback({
        conversationId: _conversationId,
        isClientSatisfied: value,
      });
      await completeExercise(userExerciseId);
      setConversationFeedback(response.feedback);
    } catch (error) {
      setFeedbackLoading(false);
    }
    setFeedbackLoading(false);
  },

  generateFeedback: async ({
    value,
    userExerciseId,
  }: {
    value: boolean;
    userExerciseId: number;
  }) => {
    const {setFeedbacksHaveBeenGenerated, onSingleConversationFeedback} = get();

    await onSingleConversationFeedback({value, userExerciseId});

    setFeedbacksHaveBeenGenerated(true);
  },

  handleEndExercise: ({
    isCourse,
    handleAdvance,
    navigate,
  }: {
    isCourse: boolean;
    handleAdvance: ({isQuiz}: {isQuiz: boolean}) => void;
    navigate: NavigateFunction;
  }) => {
    const {reset} = get();

    reset();
    if (isCourse) {
      return handleAdvance({isQuiz: false});
    }
    return navigate('/practices');
  },
}));
