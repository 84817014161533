import {showNotification} from '@mantine/notifications';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {assignUserToCourse} from '../../courses';

interface AssignUsersToCourseParams {
  courseId: number;
  userIds: number[];
}

export const useAssignUsersToCourse = () => {
  const queryClient = useQueryClient();

  const {t} = useTranslation('Courses');

  return useMutation<void, unknown, AssignUsersToCourseParams>({
    mutationFn: ({courseId, userIds}) => assignUserToCourse(courseId, userIds),
    onSuccess: (_, {courseId}) => {
      queryClient.invalidateQueries(['courses/users', courseId]);
      queryClient.invalidateQueries(['department/users', courseId]);
      showNotification({
        title: t('alertMessages.success'),
        message: t('assignCourse.successAssignUsersToCourse'),
        color: 'green',
      });
    },
    onError: () => {
      showNotification({
        title: t('alertMessages.error'),
        message: t('assignCourse.failAssignUsersToCourse'),
        color: 'red',
      });
    },
  });
};
