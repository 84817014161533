import React, {useEffect, useState} from 'react';
import {Accordion, Group, Box, Image, createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {getChapterId} from '../../../../../utils/course.util';
import {ManagerTryCourseSidebarProps} from './interfaces/ManagerTryCourseSidebarProps';
import {Text} from 'components/common';
import {CollapseButton} from '../../../../common';
import {Color} from 'enums/common';

// Styled components
const CourseSidebarContainer = styled.div`
  position: absolute;
  display: flex;
  min-height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px 0 #4d3c8233;
  right: 0;
  top: 0;
  margin-top: 2rem;
`;

const SidebarContent = styled.div`
  width: 80%;
  margin: 56px auto 0 auto;
`;

const NavbarItem = styled.div<{allowSelection: boolean; isActive: boolean}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: ${({allowSelection}) => (allowSelection ? 'pointer' : 'default')};
  background-color: ${({isActive}) => (isActive ? '#f0f0f0' : 'transparent')};
`;

const StyledCollapseButton = styled(CollapseButton)`
  position: absolute;
  top: 24px;
  left: -18px;
`;

const useSidebarStyles = createStyles({
  closed: {
    width: '4em',
    transitionDuration: '600ms',
  },
  open: {
    width: '32%',
    transitionDuration: '600ms',
  },
});

export const ManagerTryCourseSidebar = ({
  chapters,
  chapterIndex,
  chapterPageIndex,
  setChapterIndex,
  setChapterPageIndex,
}: ManagerTryCourseSidebarProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [activeChapters, setActiveChapters] = useState<string[]>([]);

  const {t} = useTranslation('Courses');

  const {classes, cx} = useSidebarStyles();

  const checkIfPageIsActive = (pageIndex: number, cIndex: number) => {
    return chapterIndex === cIndex && chapterPageIndex === pageIndex;
  };

  const handleToggleChapter = (chapterId: string) => {
    if (activeChapters.includes(chapterId)) {
      setActiveChapters(activeChapters.filter(id => id !== chapterId)); // Collapse
    } else {
      setActiveChapters([...activeChapters, chapterId]); // Expand
    }
  };

  const handleNavbarItemClick = (chapterIndex: number, pageIndex: number) => {
    setChapterIndex(chapterIndex);
    setChapterPageIndex(pageIndex);
  };

  useEffect(() => {
    setActiveChapters([getChapterId(chapters[chapterIndex])]);
  }, [chapterIndex, chapters]);

  return (
    <CourseSidebarContainer
      className={cx(isOpen ? classes.open : classes.closed)}
    >
      <StyledCollapseButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <SidebarContent>
          <Accordion multiple value={activeChapters} chevronPosition="left">
            {chapters.map((chapter, cIndex) => (
              <Accordion.Item key={cIndex} value={getChapterId(chapter)}>
                <Accordion.Control
                  style={{
                    background: `${Color.PURPLE_400}`,
                    marginBottom: '.5rem',
                  }}
                  onClick={() => handleToggleChapter(getChapterId(chapter))}
                >
                  <Text>{chapter.name}</Text> {/* Chapter name */}
                </Accordion.Control>
                <Accordion.Panel>
                  {chapter.pages.map((page, pIndex) => (
                    <NavbarItem
                      style={{padding: '.2rem'}}
                      key={pIndex}
                      allowSelection={true}
                      isActive={checkIfPageIsActive(pIndex, cIndex)}
                      onClick={() => handleNavbarItemClick(cIndex, pIndex)}
                    >
                      <Group mb={10}>
                        <Image
                          height={16}
                          width={16}
                          src={
                            page.isCompleted
                              ? '/images/done.svg'
                              : page.resources[0]
                              ? '/images/play.svg'
                              : '/images/paper.svg'
                          }
                        />
                        <Box
                          style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            flex: 1,
                          }}
                        >
                          <Text
                            fontSize={14}
                            fontWeight={
                              checkIfPageIsActive(pIndex, cIndex) ? 700 : 500
                            }
                            color={Color.DARK}
                          >
                            {page.pageTitle}
                          </Text>
                        </Box>
                      </Group>
                    </NavbarItem>
                  ))}
                  {/* Add exercises, quizzes, etc., similar to the previous implementation */}
                  {chapter.exercises.map((exercise, pIndex) => (
                    <NavbarItem
                      style={{padding: '.2rem'}}
                      key={pIndex}
                      allowSelection={true}
                      isActive={checkIfPageIsActive(pIndex, cIndex)}
                      onClick={() => handleNavbarItemClick(cIndex, pIndex)}
                    >
                      <Group
                        mb={10}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <Image
                          height={16}
                          width={exercise.isCompleted ? 16 : 12}
                          ml={exercise.isCompleted ? 0 : 1.5}
                          src={
                            exercise.isCompleted
                              ? '/images/done.svg'
                              : '/images/voicebot.svg'
                          }
                        />
                        <Box
                          style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            flex: 1,
                          }}
                        >
                          <Text
                            fontSize={14}
                            fontWeight={
                              checkIfPageIsActive(
                                pIndex + chapter.pages.length,
                                chapterIndex
                              )
                                ? 700
                                : 500
                            }
                            color={Color.DARK}
                          >
                            {exercise.title}
                          </Text>
                        </Box>
                      </Group>
                    </NavbarItem>
                  ))}
                  {chapter.quiz && (
                    <NavbarItem
                      style={{padding: '.2rem'}}
                      allowSelection={true}
                      isActive={checkIfPageIsActive(
                        chapter.pages.length + chapter.exercises.length,
                        cIndex
                      )}
                      onClick={() =>
                        handleNavbarItemClick(
                          cIndex,
                          chapter.pages.length + chapter.exercises.length
                        )
                      }
                    >
                      <Group mb={10}>
                        <Image
                          height={16}
                          width={16}
                          src={
                            chapter.quiz.isCompleted
                              ? '/images/done.svg'
                              : '/images/quiz.svg'
                          }
                        />
                        <Text
                          fontSize={14}
                          fontWeight={
                            checkIfPageIsActive(
                              chapter.pages.length + chapter.exercises.length,
                              chapterIndex
                            )
                              ? 700
                              : 500
                          }
                          color={Color.DARK}
                        >
                          {t('courseDetails.quiz')}
                        </Text>
                      </Group>
                    </NavbarItem>
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </SidebarContent>
      )}
    </CourseSidebarContainer>
  );
};
