import {Box, Button, Drawer, Select, Text} from '@mantine/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {DepartmentUser} from 'types/users/users';
import {useAssignUsersToCourse} from '../../../api/tanstack-hooks/courses/useAssignCourseMembersToCourse';
import {useGetDepartmentUsers} from '../../../api/tanstack-hooks/department/useGetDepartmentUsers';
import {DynamicTable} from '../../../components/table/DynamicTable';
import {Color} from '../../../enums/common';
import {AssignEmployeesToCourseInterface} from './interfaces/AssignEmployeesToCourseInterface';
import {getUnassignedEmployeesTableColumns} from './table-config/unassigned-employees-table-columns';

const DrawerBody = styled.div`
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ActionPanelTitle = styled(Text)`
  color: ${Color.DARK};
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
`;

export const AssignEmployeesToCourseActionPanel = ({
  courseId,
  courseTitle,
  departments,
  opened,
  onClose,
}: AssignEmployeesToCourseInterface) => {
  const {t} = useTranslation('Courses');

  const [selectedDepartmentId, setSelectedDepartmentId] = useState<
    number | null
  >(null);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const assignUsersMutation = useAssignUsersToCourse();

  const handleBulkSelect = (selectedRows: DepartmentUser[]) => {
    const selectedUserIds = selectedRows.map(user => user.userId);
    setSelectedUserIds(selectedUserIds);
  };

  const handleAssignSelectedUsers = () => {
    assignUsersMutation.mutate({courseId, userIds: selectedUserIds});
    setSelectedUserIds([]);
    onClose();
  };

  const handleDepartmentSelect = (value: string | null) => {
    setSelectedDepartmentId(value ? Number(value) : null);
  };

  const {data: unassignedUsersData} = useGetDepartmentUsers(
    +courseId,
    selectedDepartmentId
  );

  return (
    <Drawer
      position="right"
      size="lg"
      opened={opened}
      onClose={onClose}
      title={
        <ActionPanelTitle>
          {t('assignCourse.assignSelectedCourseToEmployees', {
            courseName: courseTitle,
          })}
        </ActionPanelTitle>
      }
    >
      <DrawerBody>
        <Box style={{display: 'flex', flexDirection: 'column', gap: '3rem'}}>
          <Box>
            <Select
              placeholder={t('assignCourse.chooseDepartment')}
              clearable
              nothingFound="No options"
              data={
                departments.map(dept => ({
                  value: dept.departmentId.toString(),
                  label: dept.name,
                })) || []
              }
              onChange={handleDepartmentSelect}
            />
          </Box>
          <Box>
            <DynamicTable
              data={unassignedUsersData?.data ?? []}
              columns={getUnassignedEmployeesTableColumns(t)}
              bulkSelectOption={true}
              onBulkSelect={handleBulkSelect}
            />
          </Box>
        </Box>
        <Box>
          <Button
            onClick={handleAssignSelectedUsers}
            sx={{
              background: Color.DARK,
              borderRadius: 0,
              width: '100%',
              height: 50,
              '&:hover': {
                background: Color.DARK,
              },
            }}
          >
            {t('assignCourse.assignWorkers')}
          </Button>
        </Box>
      </DrawerBody>
    </Drawer>
  );
};
