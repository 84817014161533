import styled from 'styled-components';

export const MetricsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;

  & > div {
    flex: 1;
  }
`;
