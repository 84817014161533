import {Box, Divider} from '@mantine/core';
import React, {FC} from 'react';
import styled from 'styled-components';
import {MetricCard} from 'components/common/Cards/MetricCard';
import {useTranslation} from 'react-i18next';
import {SimplifiedCourseCard} from '../../components/common/Cards/SimplifiedCourseCard/SimplifiedCourseCard';
import {getCourseType} from '../../components/courses/common/CourseTypeExtractor/courseTypeExtractor';
import {Success} from '../../components/icons';
import {AssignedCourseSummary} from '../../types/users/users';
import {UserTrophiesComponent} from './components/CustomUserTrophiesComponent/UserTrophiesComponent';
import {WeeklyPointsComponent} from './components/WeeklyPointsComponent/WeeklyPointsComponent';

interface UserMetricsProps {
  points?: number;
  completedCourses?: number;
  assignedCoursesLength?: number;
  awards?: number;
  weeklyPoints?: number;
  trophyPoints?: number;
  fullCompletedCourses?: AssignedCourseSummary[];
}

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
  min-width: 300px;
`;

const MetricHolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  gap: 0.7rem;
  margin-top: 1rem;
`;

const CourseCompletedSeparator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CoursesCompletedParent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const UserMetrics: FC<UserMetricsProps> = ({
  assignedCoursesLength,
  completedCourses,
  awards,
  weeklyPoints,
  trophyPoints,
  fullCompletedCourses,
}) => {
  const {t} = useTranslation('Dashboard');

  const renderSimplifiedCourseCard = () => (
    <MetricHolderWrapper>
      {fullCompletedCourses?.slice(0, 3).map((course, index) => (
        <React.Fragment key={index}>
          <CoursesCompletedParent>
            <CourseCompletedSeparator>
              <SimplifiedCourseCard
                fontSize={12}
                fontWeight={400}
                completedCourse={course}
                courseType={getCourseType(course.type)}
                courseTypeBadgeSize="sm"
                imageHeight={55}
                imageWidth={82}
              />
              <Box style={{marginLeft: '.1rem'}}>
                <Success />
              </Box>
            </CourseCompletedSeparator>
            <Divider size={1} />
          </CoursesCompletedParent>
        </React.Fragment>
      ))}
    </MetricHolderWrapper>
  );

  return (
    <MetricWrapper>
      <UserTrophiesComponent totalNumberOfTrophies={awards} />
      <WeeklyPointsComponent
        weeklyPoints={weeklyPoints}
        trophyPoints={trophyPoints}
      />
      <MetricCard
        title={t('metricCards.coursesCompleted')}
        metricValue={`${completedCourses ?? 0}/${assignedCoursesLength ?? 0}`}
        renderNavigationLink={true}
      >
        {renderSimplifiedCourseCard()}
      </MetricCard>
    </MetricWrapper>
  );
};
