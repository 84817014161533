import styled from 'styled-components';
import {Color} from '../../../../enums/common';

export const PaginationWrapper = styled.div`
  padding: 12px;
  background-color: white;
  margin-top: 1px;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 6px;

  input {
    height: 26px;
    width: 120px;
    min-height: unset;
    color: ${Color.DARK};
  }
`;
