import {CourseCategory, CourseType} from '../../../../types/courses/courses';

export const getCourseType = (categories: CourseCategory[]): CourseType => {
  const ids = categories.map(category => category.id) || [];

  const hasVideo = ids.includes(CourseType.VIDEO);
  const hasExercise = ids.includes(CourseType.EXERCISE);

  if (hasVideo && hasExercise) {
    return CourseType.BOTH;
  } else if (hasVideo) {
    return CourseType.VIDEO;
  } else if (hasExercise) {
    return CourseType.EXERCISE;
  } else {
    return CourseType.NONE;
  }
};
