import {useMutation, useQueryClient} from '@tanstack/react-query';
import {assignCourses} from 'api/courses';

interface AssignCoursesParams {
  usersIds: string[];
  courseIds: string[];
}

export const useAssignCourses = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({usersIds, courseIds}: AssignCoursesParams) =>
      assignCourses(usersIds, courseIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['assignableCourses']);
        queryClient.invalidateQueries(['teamOverview']);
      },
    }
  );
};
