import {Image} from '@mantine/core';
import React from 'react';
import styled from 'styled-components';
import {Color} from '../../../../enums/common';
import {getCourseTypeBadge} from '../../../courses/common/CourseTypeBadgeExtractor/getCourseTypeBadge';
import {SimplifiedCourseCardProps} from './interfaces/SimplifiedCourseCardProps';

const SimplifiedCourseDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface TitleStyleProps {
  fontWeight: number;
  fontSize: number;
}

const CourseTitle = styled.span<TitleStyleProps>`
  color: ${Color.DARK};
  font-size: ${({fontSize}) => (fontSize ? `${fontSize}px` : '12px')};
  font-style: normal;
  font-weight: ${({fontWeight}) => fontWeight || 400};
  line-height: 14px;
`;

export const SimplifiedCourseCard = ({
  completedCourse,
  courseType,
  courseTypeBadgeSize,
  imageWidth,
  imageHeight,
  fontWeight,
  fontSize,
}: SimplifiedCourseCardProps) => {
  return (
    <ContentWrapper>
      <Image
        src={completedCourse.image ?? '/images/default.jpeg'}
        height={imageHeight}
        width={imageWidth}
        alt="no image"
        radius={5}
      />
      <SimplifiedCourseDataWrapper>
        <CourseTitle fontWeight={fontWeight} fontSize={fontSize}>
          {completedCourse.courseName}
        </CourseTitle>
        {getCourseTypeBadge(courseType, courseTypeBadgeSize, 13, 12)}
      </SimplifiedCourseDataWrapper>
    </ContentWrapper>
  );
};
