// Components
import {
  Box,
  Button,
  Container,
  Divider,
  Image,
  Select,
  Space,
  Text,
} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';

// Hooks
import {useGetCourseOverview} from 'api/tanstack-hooks/courses';
import {useGetCourseUsers} from 'api/tanstack-hooks/courses/useGetCourseUsers';
import {useGetDepartmentNames} from 'api/tanstack-hooks/department/useGetDepartmentNames';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {UserPlus} from 'tabler-icons-react';
import {BasePage} from '../../components/common';
import {DynamicPagination} from '../../components/DynamicPagination/DynamicPagination';
import {DynamicTable, SortParams} from '../../components/table/DynamicTable';
import {getPaginationOptions} from '../../components/table/table-config/pagination-options';

// Other
import {Color} from '../../enums/common';
import useLocalAndUrlStore from '../../store/useLocalAndUrlStore';
import {CourseUser} from '../../types/users/users';
import {PaginationWrapper} from '../dashboard/components/PaginationWrapper/PaginationWrapper';
import {AssignEmployeesToCourseActionPanel} from './components/AssignEmployeesToCourseActionPanel';
import {getColumns} from './table-config/assign-users-table-columns';

// Styled components
const StyledContainer = styled(Container)`
  margin-top: 30px;
  max-width: 80%;
  @media screen and (min-width: 1728px) {
    max-width: 67%;
  }
  @media screen and (min-width: 1920px) {
    max-width: 61%;
  }
`;

const StyledTitle = styled(Text)`
  font-size: 32px;
  line-height: 1.3em;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-bottom: 10px;
`;

const StyledTitleMd = styled(Text)`
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-bottom: 10px;
`;

const StyledOverviewEmployeeSpan = styled(Text)`
  color: ${Color.DARK};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

const SelectWrapper = styled('div')`
  max-width: 274px;
  min-width: 274px;
`;

const SpaceBetween = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImageAndTitleWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 1.5rem;
  align-items: center;
`;

const MainTitleAndActionButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonTextSpan = styled.div`
  color: ${Color.WHITE};
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.3px;
`;

export const AssignCourse: React.FC = () => {
  const {courseId = ''} = useParams();
  const {t} = useTranslation('Courses');
  const columns = getColumns(t);
  const [opened, {open, close}] = useDisclosure(false);

  const {page, size, setPage, setSize} = useLocalAndUrlStore();
  const [sortKey, setSortKey] = useState<string>('');

  // Hooks
  const {data: departmentNamesData} = useGetDepartmentNames();
  const {data} = useGetCourseOverview(+courseId);
  const courseTitle = data?.data.courseName ?? '';
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<
    number | null
  >(null);
  const {data: courseUsersData} = useGetCourseUsers(
    +courseId,
    selectedDepartmentId,
    page,
    size,
    sortKey
  );

  const courseUsers = courseUsersData?.data?.elements || [];

  const totalPages = Math.ceil(
    (courseUsersData?.data?.elements.length || 0) / size
  );

  const handleDepartmentSelect = (value: string | null) => {
    setSelectedDepartmentId(value ? Number(value) : null);
  };

  const handleSort = ({key, order}: SortParams<CourseUser>) => {
    if (key && order) {
      setSortKey(`${key},${order.toUpperCase()}`);
    } else {
      setSortKey('');
    }
  };

  return (
    <BasePage>
      <AssignEmployeesToCourseActionPanel
        courseId={Number(courseId)}
        departments={departmentNamesData?.data || []}
        courseTitle={courseTitle}
        opened={opened}
        onClose={close}
      />
      <StyledContainer>
        <MainTitleAndActionButtonWrapper>
          <ImageAndTitleWrapper>
            {data?.data.coverImage && (
              <Box>
                <Image width={128} height={74} src={data?.data.coverImage} />
              </Box>
            )}
            <Box>
              <StyledTitle>{courseTitle}</StyledTitle>
              <StyledTitleMd>
                {t('assignCourse.assignACourseToEmployees')}
              </StyledTitleMd>
            </Box>
          </ImageAndTitleWrapper>
          <Button
            onClick={open}
            size="md"
            style={{background: `${Color.DARK}`}}
            radius={0}
            leftIcon={<UserPlus size="15" />}
          >
            <ButtonTextSpan>{t('assignCourse.assignWorkers')}</ButtonTextSpan>
          </Button>
        </MainTitleAndActionButtonWrapper>
        <Space h="xl" />
        <Divider />
        <Space h="xl" />
        <SpaceBetween>
          <StyledOverviewEmployeeSpan>
            {t('assignCourse.overviewOfCourseEmployees')}
          </StyledOverviewEmployeeSpan>
          <SelectWrapper>
            <Select
              placeholder={t('assignCourse.chooseDepartment')}
              clearable
              nothingFound="No options"
              data={
                departmentNamesData?.data?.map(dept => ({
                  value: dept.departmentId.toString(),
                  label: dept.name,
                })) || []
              }
              onChange={handleDepartmentSelect}
            />
          </SelectWrapper>
        </SpaceBetween>
        <Space h="md" />
        <DynamicTable
          data={courseUsers}
          columns={columns}
          onSort={handleSort}
        />
        <PaginationWrapper>
          {courseUsers.length > 0 && (
            <DynamicPagination
              page={page}
              totalPages={totalPages}
              size={size}
              setSize={setSize}
              setPage={setPage}
              paginationOptions={getPaginationOptions()}
            />
          )}
        </PaginationWrapper>
      </StyledContainer>
      <Space h="xl" />
      <Space h="xl" />
    </BasePage>
  );
};
