import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Select, Input, MultiSelect, Modal, Button} from '@mantine/core';
import styled from 'styled-components';
import {
  AlertTriangle,
  Search,
  UserExclamation,
  Users,
  InfoCircle,
  ArrowRight,
} from 'tabler-icons-react';

// hooks
import {useGetDepartmentNames} from 'api/tanstack-hooks/department/useGetDepartmentNames';
import {useTeamOverview} from 'api/tanstack-hooks/department/useTeamOverview';
import {useDashboardMetrics} from 'api/tanstack-hooks/department/useDashboardMetrics';
import {useGetProfileInfo} from 'api/tanstack-hooks/users';
import useLocalAndUrlStore from 'store/useLocalAndUrlStore';
import {Color} from 'enums/common';
import {DynamicPagination} from '../../../components/DynamicPagination/DynamicPagination';
import {ExpandableDatePicker} from '../../../components/ExpandableDatePicker/ExpandableDatePicker';
import {getPaginationOptions} from '../../../components/table/table-config/pagination-options';
import {MetricsWrapper} from '../components/MetricsWrapper/MetricsWrapper';

// Components
import {AssignCourses} from './_AssignCourses';
import {DynamicTable} from 'components/table/DynamicTable';
import {MetricCard} from 'components/common/Cards/MetricCard';
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import {TitleWrapper} from '../components/TitleWrapper/TitleWrapper';
import {InputsGroup} from '../components/InputsGroup/InputsGroup';
import {getManagerUsersTableColumns} from './table-config/manager-users-table-columns';

// Style
const Header = styled.div`
  font-family: 'Manrope', sans-serif;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

const Title = styled.div`
  font-family: 'Manrope', sans-serif;
  color: #fa5f4f;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const SubTitle = styled.div`
  font-family: 'Manrope', sans-serif;
  color: ${Color.DARK};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TableTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: ${Color.DARK};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  gap: 8px;
`;

const PaginationWrapper = styled.div`
  padding: 12px;
  background-color: white;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 6px;

  input {
    height: 26px;
    width: 120px;
    min-height: unset;
    color: ${Color.DARK};
  }
`;

export const ManagerDashboard = () => {
  // State
  const [sortKey, setSortKey] = useState<string>('');
  const [userId, setUserId] = useState<number | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showPending, setShowPending] = useState<boolean>(false);

  // Hooks
  const {
    departmentId,
    page,
    size,
    from,
    to,
    levelIds,
    searchKey,
    setDepartmentId,
    setPage,
    setSize,
    setFrom,
    setTo,
    setLevelIds,
    setSearchKey,
  } = useLocalAndUrlStore();

  const {t} = useTranslation('Dashboard');

  // Services
  const {data: departmentNamesData} = useGetDepartmentNames();
  const {data: dashboardMetricsData} = useDashboardMetrics({
    departmentId: Number(departmentId),
    from: from ?? undefined,
    to: to ?? undefined,
  });
  const {data: userProfile} = useGetProfileInfo();
  const {data: teamOverviewData, isLoading} = useTeamOverview({
    departmentId: Number(departmentId),
    page,
    size,
    sortKey,
    from: from ?? undefined,
    to: to ?? undefined,
    levelIds: levelIds.map(id => Number(+id)).join(','),
    searchKey: searchKey,
    showPending,
  });

  // Handlers
  let timerId: ReturnType<typeof setTimeout>;

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      setSearchKey(value);
    }, 700);
  }

  const handleFilterPendingUsers = () => {
    setShowPending(!showPending);
  };

  const handleAllUsersFilter = () => {
    setShowPending(false);
  };

  // Calculations
  const departmentSelectOptions =
    departmentNamesData?.data.map(({name, departmentId}) => ({
      label: name,
      value: departmentId.toString(),
    })) || [];

  const levelOptions = [
    {value: '1', label: t('courseCards.beginner')},
    {value: '2', label: t('courseCards.intermediate')},
    {value: '3', label: t('courseCards.expert')},
  ];

  const {pendingMembers, totalMembers, underPerformingMembers} =
    dashboardMetricsData?.data || {};

  const {fullName, email} = userProfile?.data || {};

  useEffect(() => {
    if (departmentNamesData?.data?.length) {
      setDepartmentId(departmentNamesData?.data[0]?.departmentId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentNamesData?.data]);

  const totalPages = Math.ceil((teamOverviewData?.data?.total || 0) / size);

  return (
    <BasePage>
      <MainContainer>
        <Header>
          <TitleWrapper>
            <Title>{`${t('welcomeBanner.hi')}, ${
              fullName ?? email ?? ''
            }!`}</Title>
            <SubTitle>{t('welcomeBanner.subTitle')}</SubTitle>
          </TitleWrapper>

          <InputsGroup>
            <Select
              placeholder={t('inputs.team')}
              data={departmentSelectOptions}
              value={departmentId}
              onChange={setDepartmentId}
              styles={{
                item: {
                  '&[data-selected], &[data-hovered]': {
                    backgroundColor: Color.GRAY_50,
                    color: Color.DARK,
                  },
                  '&[data-selected]': {
                    '&:hover': {
                      backgroundColor: Color.GRAY_50,
                    },
                  },
                },
              }}
            />
          </InputsGroup>
        </Header>

        <MetricsWrapper>
          <MetricCard
            title={t('metricCards.totalMembers')}
            icon={<Users />}
            metricValue={totalMembers}
          />
          <MetricCard
            title={t('metricCards.underperforming')}
            icon={<AlertTriangle />}
            metricValue={`${underPerformingMembers ?? '-'}/${
              totalMembers ?? '-'
            }`}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '1.5rem',
              }}
            >
              <Button
                onClick={handleAllUsersFilter}
                variant="subtle"
                size="xs"
                leftIcon={<InfoCircle size={16} />}
                rightIcon={<ArrowRight size={16} />}
                styles={{
                  root: {
                    color: Color.GREEN_050,
                    fontFeatureSettings: "'liga' off, 'clig' off",
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.3px',
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'solid',
                    textDecorationSkipInk: 'none',
                    textDecorationThickness: 'auto',
                    textUnderlineOffset: 'auto',
                    textUnderlinePosition: 'from-font',
                    backgroundColor: 'transparent',
                    padding: 0,
                    '&:hover': {
                      background: 'none',
                      textDecorationLine: 'underline',
                    },
                  },
                }}
              >
                {t('seeUnderperformingUsers')}
              </Button>
            </div>
          </MetricCard>
          <MetricCard
            title={t('metricCards.pendingInvitations')}
            icon={<UserExclamation />}
            metricValue={
              <>
                <span style={{color: 'red'}}>{pendingMembers ?? '-'}</span>/
                {totalMembers ?? '-'}
              </>
            }
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '1.5rem',
              }}
            >
              <Button
                onClick={handleFilterPendingUsers}
                variant="subtle"
                size="xs"
                leftIcon={<InfoCircle size={16} />}
                rightIcon={<ArrowRight size={16} />}
                styles={{
                  root: {
                    color: Color.GREEN_050,
                    fontFeatureSettings: "'liga' off, 'clig' off",
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.3px',
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'solid',
                    textDecorationSkipInk: 'none',
                    textDecorationThickness: 'auto',
                    textUnderlineOffset: 'auto',
                    textUnderlinePosition: 'from-font',
                    backgroundColor: 'transparent',
                    padding: 0,
                    '&:hover': {
                      background: 'none',
                      textDecorationLine: 'underline',
                    },
                  },
                }}
              >
                {showPending
                  ? t('hidePendingInvitations')
                  : t('viewPendingInvitations')}
              </Button>
            </div>
          </MetricCard>
        </MetricsWrapper>

        <TableHeader>
          <TableTitle>{t('table.title')}</TableTitle>

          <InputsGroup>
            <Input
              defaultValue={searchKey}
              icon={<Search size={20} />}
              placeholder="Search"
              onChange={handleSearch}
            />
            <MultiSelect
              data={levelOptions}
              value={levelIds}
              placeholder={t('level')}
              onChange={e => setLevelIds(e)}
            />
            <ExpandableDatePicker
              setFrom={setFrom}
              setTo={setTo}
              placeholder={t('chooseDateRange')}
              initialRange={[
                dayjs().startOf('week').add(1, 'day').toDate(),
                dayjs().endOf('week').add(1, 'day').toDate(),
              ]}
            />
          </InputsGroup>
        </TableHeader>
        <DynamicTable
          isLoading={departmentId ? isLoading : false}
          data={teamOverviewData?.data?.elements || []}
          onSort={({key, order}) => {
            if (key && order) {
              setSortKey(`${String(key)},${order.toUpperCase()}`);
            } else {
              setSortKey('');
            }
          }}
          columns={getManagerUsersTableColumns(
            t,
            setUserId,
            setOpenModal,
            setUserName,
            departmentId
          )}
          rowsPerPage={size}
          height="fixed"
        />
        <PaginationWrapper>
          <DynamicPagination
            page={page}
            totalPages={totalPages}
            size={size}
            setSize={setSize}
            setPage={setPage}
            paginationOptions={getPaginationOptions()}
          />
        </PaginationWrapper>

        <Modal
          opened={openModal}
          onClose={() => {
            setUserId(null);
            setUserName(null);
            setOpenModal(false);
          }}
          padding={24}
          size="lg"
          styles={{
            header: {
              backgroundColor: 'unset',
            },
          }}
        >
          {userId && userName && (
            <AssignCourses
              userName={userName}
              userId={userId}
              onClose={setOpenModal}
            />
          )}
        </Modal>
      </MainContainer>
    </BasePage>
  );
};
