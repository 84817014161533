import React, {ReactNode, useState} from 'react';
import {Loader, Table} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

// Types
import {Color} from 'enums/common';

// Components
import {NoDataPlaceholder} from 'components/common';
import {AscendingSortingIconButton} from '../../pages/TeamManagement/components/TeamManagementSettings/icon-button-components/AscendingSortingIconButton';
import {DescendingSortingIconButton} from 'pages/TeamManagement/components/TeamManagementSettings/icon-button-components/DescendingSortingIconButton';
import {UnsortedIconButton} from '../../pages/TeamManagement/components/TeamManagementSettings/icon-button-components/UnsortedIconButton';

const Wrapper = styled.div<{height?: string}>`
  background-color: white;
  height: ${({height = 'auto'}) => (height === 'fixed' ? '700px' : height)};
  overflow-y: auto;
`;

const StyledTable = styled(Table)`
  white-space: nowrap;
  border-collapse: collapse;
  border-bottom: 1px solid ${Color.GRAY};
  & thead {
    & tr > th {
      padding: 14px 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: ${Color.DARK};
    }
  }

  & tbody {
    & tr > td {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: ${Color.DARK};
    }
  }
`;

const THWrapper = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const LoaderWrapper = styled.div<{height: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => height};
`;

interface Title {
  name: string;
  sortable: boolean;
  order?: 'asc' | 'desc' | undefined;
}

export interface Column<T> {
  slug: keyof T | string;
  title: Title;
  key: keyof T | string;
  render?: (value: T) => ReactNode;
  isSticky?: boolean;
  customColumnColor?: (value: T) => string | undefined;
}

export interface SortParams<T> {
  key: keyof T;
  order: 'asc' | 'desc' | '' | undefined;
}

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  iconAction?: (row: T) => ReactNode;
  onSort?: ({key, order}: SortParams<T>) => void;
  isLoading?: boolean;
  rowsPerPage?: number;
  height?: 'fixed' | 'auto';
  bulkSelectOption?: boolean;
  onBulkSelect?: (selected: T[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DynamicTable = <T extends Record<string, any>>({
  data,
  columns,
  iconAction,
  onSort,
  isLoading = false,
  height = 'auto',
  rowsPerPage,
  bulkSelectOption = false,
  onBulkSelect,
}: TableProps<T> & {onBulkSelect?: (selected: T[]) => void}) => {
  const {t} = useTranslation('Dashboard');
  const [sortConfig, setSortConfig] = useState<{
    key: keyof T;
    order: 'asc' | 'desc' | undefined;
  } | null>(null);
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const handleSort = (sortedKey: keyof T) => {
    if (!sortedKey) return;
    const nextOrder: 'asc' | 'desc' | undefined =
      sortConfig && sortConfig.key === sortedKey
        ? sortConfig.order === 'asc'
          ? 'desc'
          : sortConfig.order === 'desc'
          ? undefined
          : 'asc'
        : 'asc';

    const newSortConfig = {key: sortedKey, order: nextOrder};

    setSortConfig(newSortConfig);

    if (nextOrder === undefined) {
      onSort?.({key: '', order: ''});
    } else {
      onSort?.({key: sortedKey, order: nextOrder});
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
      onBulkSelect?.([]);
    } else {
      setSelectedRows(data);
      onBulkSelect?.(data);
    }
  };

  const handleSelectRow = (row: T) => {
    const isSelected = selectedRows.includes(row);
    const newSelected = isSelected
      ? selectedRows.filter(selectedRow => selectedRow !== row)
      : [...selectedRows, row];

    setSelectedRows(newSelected);
    onBulkSelect?.(newSelected);
  };

  const sortedColumns = [
    ...columns.map(column => ({
      ...column,
      title: {
        ...column.title,
        order:
          sortConfig && sortConfig.key === column.key
            ? sortConfig.order
            : undefined,
      },
    })),
  ];

  const rows = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={sortedColumns.length} align="center">
            <LoaderWrapper
              height={
                height === 'fixed'
                  ? '650px'
                  : rowsPerPage === 5
                  ? '200px'
                  : '400px'
              }
            >
              <Loader
                color="#3C2C6C"
                style={{verticalAlign: 'middle'}}
                size="md"
              />
            </LoaderWrapper>
          </td>
        </tr>
      );
    }

    if (!data.length) {
      return (
        <tr>
          <td colSpan={sortedColumns.length} align="center">
            <LoaderWrapper height={'650px'}>
              <NoDataPlaceholder
                title={t('noTeamMemberDataAvailable')}
                content={t('noTeamMemberDataContent')}
                align="center"
                imageSrc="/images/noFeedbackTab.png"
              />
            </LoaderWrapper>
          </td>
        </tr>
      );
    }

    return data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {bulkSelectOption && (
          <td
            style={{
              padding: '10px',
              position: 'sticky',
              left: 0,
              zIndex: 2,
              backgroundColor: 'white',
            }}
          >
            <input
              type="checkbox"
              checked={selectedRows.includes(row)}
              onChange={() => handleSelectRow(row)}
            />
          </td>
        )}
        {columns.map(({key, render, customColumnColor}, colIndex) => (
          <td
            key={`${colIndex}_${String(key)}`}
            style={{
              ...(colIndex === 0 && {
                position: 'sticky',
                left: 0,
                zIndex: 2,
              }),
              backgroundColor: customColumnColor?.(row) ?? 'white',
            }}
          >
            {render ? render(row) : row[key]}
          </td>
        ))}
        {iconAction && <td>{iconAction(row)}</td>}
      </tr>
    ));
  };

  return (
    <Wrapper height={height}>
      <div style={{width: '100%', paddingBottom: 16}}>
        <StyledTable
          style={{
            backgroundColor: 'white',
            borderRadius: 4,
            borderCollapse: 'separate',
            borderSpacing: 0,
          }}
        >
          <thead>
            <tr
              style={{
                backgroundColor: '#F1F0F4',
                position: 'sticky',
                top: 0,
                zIndex: 15,
              }}
            >
              {bulkSelectOption && (
                <th
                  style={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 2,
                    backgroundColor: '#F1F0F4',
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows.length === data.length}
                  />
                </th>
              )}
              {sortedColumns.map(({title, key}, index) => (
                <th
                  key={index}
                  style={{
                    cursor: title.sortable ? 'pointer' : 'default',
                    ...(index === 0 && {
                      position: 'sticky',
                      left: bulkSelectOption ? '30px' : '0px',
                      zIndex: 2,
                      backgroundColor: '#F1F0F4',
                    }),
                  }}
                  onClick={() => title.sortable && handleSort(key)}
                >
                  <THWrapper
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {title.name}
                    {title.sortable &&
                      ('order' in title ? (
                        title.order === 'asc' ? (
                          <AscendingSortingIconButton />
                        ) : title.order === 'desc' ? (
                          <DescendingSortingIconButton />
                        ) : (
                          <UnsortedIconButton />
                        )
                      ) : (
                        <UnsortedIconButton />
                      ))}
                  </THWrapper>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{rows()}</tbody>
        </StyledTable>
      </div>
    </Wrapper>
  );
};
