import styled from 'styled-components';
import {Color} from '../../../../enums/common';

export const InputsGroup = styled.div`
  min-width: max-content;
  display: flex;
  gap: 8px;
  align-self: flex-end;

  & input {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.DARK};
  }
`;
