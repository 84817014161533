import {UserContext} from '../../../contexes';
import {useMutation} from '@tanstack/react-query';
import i18n from '../../../i18n';
import {LoginPayload} from '../../../types/auth/Auth';
import {
  endNotification,
  generateRandomString,
  startNotification,
} from '../../../utils/common';
import {login} from '../../services/auth';
import {useContext} from 'react';

export const useLogin = () => {
  const [, setUserContext] = useContext(UserContext);
  const randomId = generateRandomString(20);

  return useMutation((payload: LoginPayload) => login(payload), {
    onMutate: () => {
      startNotification(randomId);
      localStorage.removeItem('authToken');
    },
    onSuccess: response => {
      localStorage.setItem('authToken', response.data.access_token);
      localStorage.removeItem('table-params');
      endNotification(randomId, i18n.t('success', {ns: 'Auth'}), true);

      setUserContext(response.data);
    },
    onError: () => {
      endNotification(randomId, i18n.t('failed', {ns: 'Auth'}), false);
    },
  });
};
