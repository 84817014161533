import {Anchor, Box, Breadcrumbs, Button, Image, Text} from '@mantine/core';
import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import ChatBotV3Container from '../../../../containers/chatbots/ChatBotV3Container';
import {Color} from '../../../../enums/common';
import {ExerciseType} from '../../../../enums/practices';
import {BreadcrumbsStyle} from '../../../../styles';
import {DarkButtonStyle} from '../../../../styles/buttons';
import {Exercise} from '../../../../types/chapters/chapters';
import {CourseQuiz} from '../../common';
import {ManagerTryCourseProps} from './interfaces/ManagerTryCourseProps';
import {ArrowNarrowRight} from 'tabler-icons-react';
import {ManagerTryCourseSidebar} from './ManagerTryCouseSidebar/ManagerTryCourseSidebar';
import {useStartManagerTestExercise} from 'api/tanstack-hooks/practices/useManagerTestExercise';

const ModifiedDarkButtonStyle = {
  ...DarkButtonStyle,
  alignSelf: 'flex-end',
  marginTop: '10px',
};

const CourseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -40px;
  text-align: center;
  width: 56em;

  @media screen and (min-width: 1728px) {
    margin-left: 40px;
  }
  @media screen and (min-width: 1920px) {
    margin-left: 80px;
  }
`;

const StyledImage = styled(Image)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledVideo = styled.video`
  width: 100%;
`;

const DescriptionText = styled(Text)`
  padding-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${Color.DARK};
`;

const PageTitle = styled(Text)`
  padding-top: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: ${Color.DARK};
`;

const StyledText = styled(Text)`
  padding-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${Color.DARK};
`;

export const ManagerTryCourse = ({
  course,
  chapters,
  onClose,
}: ManagerTryCourseProps) => {
  const {t} = useTranslation('Practices');
  const videoRef = useRef<HTMLVideoElement>(null);

  const [chapterIndex, setChapterIndex] = useState(0);
  const [chapterPageIndex, setChapterPageIndex] = useState(0);
  const [activeChapter, setActiveChapter] = useState(0);

  const [exercise, setExercise] = useState<Exercise>();
  const [userExerciseId, setUserExerciseId] = useState<number | null>(null);
  const [conversationId, setConversationId] = useState<number | null>(null);

  const startExerciseMutation = useStartManagerTestExercise();

  const chapter = chapters[chapterIndex];
  const chapterPage = chapter?.pages[chapterPageIndex];

  const chapterPageLength =
    chapter.pages.length + chapter.exercises.length + (chapter.quiz ? 1 : 0);

  const resource = chapterPage?.resources[0];

  const handleStartExercise = async (exercise?: Exercise) => {
    if (!exercise || !chapter) return;
    startExerciseMutation.mutate(exercise?.id, {
      onSuccess: response => {
        setUserExerciseId(response.userExerciseId);
        setConversationId(response.conversationId);
        setExercise(exercise);
      },
    });
  };

  const breadcrumbItems = [
    {title: t('anchor.home'), href: '/'},
    {title: t('anchor.courses'), href: '/courses'},
    {title: `${course.courseName}`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  const handleAdvanceCourse = () => {
    setUserExerciseId(null);
    setConversationId(null);
    setExercise(undefined);

    if (
      chapterIndex === chapters.length - 1 &&
      chapterPageIndex >= chapterPageLength - 1
    ) {
      onClose();
      return;
    }

    if (chapterPageIndex < chapterPageLength - 1) {
      setChapterPageIndex(prev => prev + 1);
    } else if (chapterIndex < chapters.length - 1) {
      setChapterIndex(prev => prev + 1);
      setChapterPageIndex(0);
    }
    setActiveChapter(chapterIndex);
  };

  useEffect(() => {
    if (
      (resource?.resourceType.id === 2 || resource?.resourceType.id === 1) &&
      videoRef.current
    ) {
      videoRef.current.load();
    }
  }, [resource]);

  useEffect(() => {
    handleStartExercise(exercise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise, chapter]);

  useEffect(() => {
    const exercise = chapter.exercises[chapterPageIndex - chapter.pages.length];
    setExercise(exercise);
  }, [
    chapterIndex,
    chapterPageIndex,
    chapterPage?.resources,
    chapter.exercises,
    chapter.pages,
    chapter,
    chapters,
  ]);

  return (
    <Box style={{padding: '2rem', width: '65%'}}>
      <Breadcrumbs mb="sm" separator="→" styles={BreadcrumbsStyle}>
        {breadcrumbItems}
      </Breadcrumbs>
      <Box>
        {chapter && chapterPage && chapterPageIndex < chapterPageLength && (
          <CourseContentContainer>
            {resource && resource.resourceType.id === 0 && (
              <ImageDiv>
                <StyledImage width={400} height={250} src={resource.url} />
              </ImageDiv>
            )}
            {resource && resource.resourceType.id === 1 && (
              <StyledVideo controls ref={videoRef} controlsList="nodownload">
                <source src={resource.url} type="audio/wav" />
              </StyledVideo>
            )}
            {resource && resource.resourceType.id === 2 && (
              <>
                <StyledVideo controls ref={videoRef} controlsList="nodownload">
                  <source src={resource.url} type="video/mp4" />
                </StyledVideo>
                {chapterPage?.text?.length && (
                  <DescriptionText>{chapterPage.text}</DescriptionText>
                )}
              </>
            )}
            {!resource && (
              <>
                <StyledText>{chapter.name}</StyledText>
                <PageTitle>{chapterPage.pageTitle}</PageTitle>
                <StyledText>
                  {chapter.pages[chapterPageIndex]?.pageSummary}
                </StyledText>
              </>
            )}
            <Button
              onClick={handleAdvanceCourse}
              rightIcon={
                <ArrowNarrowRight size={26} strokeWidth={1.4} color="white" />
              }
              style={ModifiedDarkButtonStyle}
            >
              {t('next')}
            </Button>
          </CourseContentContainer>
        )}
        {chapter &&
          !chapterPage &&
          exercise &&
          chapterPageIndex < chapterPageLength && (
            <>
              {userExerciseId &&
                conversationId &&
                exercise.exerciseType === ExerciseType.CHAT_BOT_V3 && (
                  <ChatBotV3Container
                    userExerciseId={userExerciseId}
                    exercise={exercise}
                    conversationId={conversationId}
                    isCourse
                    handleAdvance={handleAdvanceCourse}
                  />
                )}
            </>
          )}
        {chapter && !chapterPage && !exercise && chapter.quiz && (
          <CourseQuiz quiz={chapter.quiz} callback={handleAdvanceCourse} />
        )}
      </Box>
      <ManagerTryCourseSidebar
        chapters={chapters}
        chapterIndex={chapterIndex}
        chapterPageIndex={chapterPageIndex}
        setChapterIndex={setChapterIndex}
        setChapterPageIndex={setChapterPageIndex}
        activeChapter={activeChapter}
        setActiveChapter={setActiveChapter}
      />
    </Box>
  );
};
