import React, {useContext} from 'react';

import {UserDashboard} from './UserDashboard/UserDashboard';
import {ManagerDashboard} from './ManagerDashboard/ManagerDashboard';

import {UserContext} from '../../contexes';

import {Role} from 'enums/auth';

export const Dashboard: React.FC = () => {
  const [userContext] = useContext(UserContext);

  if (userContext.authority.includes(Role.MANAGER)) {
    return <ManagerDashboard />;
  }

  return <UserDashboard />;
};
