import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

// Types
import {Color} from 'enums/common';
import {ChevronRight} from 'tabler-icons-react';
import {AssignedCourseSummary} from '../../../types/users/users';
import {Button, Divider} from '@mantine/core';
import {getCourseType} from '../../courses/common/CourseTypeExtractor/courseTypeExtractor';
import {SimplifiedCourseCard} from '../Cards/SimplifiedCourseCard/SimplifiedCourseCard';

interface FeedbackCardProps {
  feedbackCourses: AssignedCourseSummary[];
  feedbackNavigation: string;
}

// Create the styled components
const Wrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: ${Color.WHITE};
  padding: 30px 24px 30px 24px;
  box-shadow: 0 2px 4px 0 rgba(77, 60, 130, 0.2);
`;

const SimplifiedCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 0.5rem;
`;

const CourseCompletedSeparator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const FeedbackCard: FC<FeedbackCardProps> = ({
  feedbackCourses,
  feedbackNavigation,
}) => {
  const navigate = useNavigate();
  const {t} = useTranslation('Dashboard');
  return (
    <Wrapper>
      <SimplifiedCardWrapper>
        {feedbackCourses.slice(0, 3).map((course, index) => (
          <React.Fragment key={index}>
            <CourseCompletedSeparator>
              <SimplifiedCourseCard
                fontSize={16}
                fontWeight={400}
                completedCourse={course}
                courseType={getCourseType(course.type)}
                courseTypeBadgeSize="sm"
                imageHeight={55}
                imageWidth={82}
              />
              <Button
                onClick={() =>
                  navigate(`${feedbackNavigation}?courseId=${course.courseId}`)
                }
                variant="subtle"
                size="xs"
                rightIcon={<ChevronRight size={20} />}
                styles={{
                  root: {
                    color: Color.GREEN_050,
                    fontFeatureSettings: "'liga' off, 'clig' off",
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.3px',
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'solid',
                    textDecorationSkipInk: 'none',
                    textDecorationThickness: 'auto',
                    textUnderlineOffset: 'auto',
                    textUnderlinePosition: 'from-font',
                    backgroundColor: 'transparent',
                    padding: 0,
                    '&:hover': {
                      background: 'none',
                      textDecorationLine: 'underline',
                    },
                  },
                }}
              >
                {t('courseCards.view')}
              </Button>
              <Divider orientation={'vertical'} size={1} />
            </CourseCompletedSeparator>
          </React.Fragment>
        ))}
      </SimplifiedCardWrapper>
      <Button
        radius={0}
        onClick={() => navigate(feedbackNavigation)}
        styles={{
          root: {
            color: Color.WHITE,
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.3px',
            textDecorationStyle: 'solid',
            textDecorationSkipInk: 'none',
            textDecorationThickness: 'auto',
            textUnderlineOffset: 'auto',
            textUnderlinePosition: 'from-font',
            backgroundColor: `${Color.DARK}`,
            '&:hover': {
              background: `${Color.DARK}`,
            },
          },
        }}
      >
        {t('goToTheFeedbacks')}
      </Button>
    </Wrapper>
  );
};

export default FeedbackCard;
