import {Badge, Box} from '@mantine/core';
import React from 'react';
import {Trophy} from '../../../../components/icons/Trophy/Trophy';
import {CustomTrophiesBadgeInterface} from './interface/CustomTrophiesBadgeInterface';

export const CustomTrophiesBadgeComponent = ({
  displayText,
}: CustomTrophiesBadgeInterface) => {
  return (
    <Badge
      tt="none"
      color="#f6f4fd"
      size="sm"
      styles={() => ({
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: '#968db5',
          padding: '1rem .85rem 1rem 5px',
        },
      })}
    >
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          marginRight: 8,
          verticalAlign: 'middle',
        }}
      >
        <Box
          style={{
            background: 'white',
            borderRadius: '50%',
            padding: '.2rem',
          }}
        >
          <Trophy height={16} width={16} />
        </Box>
      </Box>
      <span
        style={{
          color: '#26145C',
          fontSize: '10px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          letterSpacing: '0.15px',
        }}
      >
        {displayText}
      </span>
    </Badge>
  );
};
