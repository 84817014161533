import {Space, Avatar, Anchor, Select, Button, ActionIcon} from '@mantine/core';
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import {Color} from 'enums/common';
import React, {useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {ArrowRight} from 'tabler-icons-react';

import FeedbackCard from 'components/common/FeedbackCard/FeedbackCard';
import {useUserOverview} from 'api/tanstack-hooks/users/useUserOverview';
import {useCurrentWeeksOfYear} from 'utils/common/datesUtil';
import {capitalizeFirstLetter} from 'utils/common';
import {ChartHeader} from '../dashboard/components/ChartHeader/ChartHeader';
import {ArrowLeftIcon} from '../dashboard/components/ChartTitleAndWeekSelectionWrapper/icon-components/ArrowLeftIcon';
import {ArrowRightIcon} from '../dashboard/components/ChartTitleAndWeekSelectionWrapper/icon-components/ArrowRightIcon';
import {TableTitle} from '../dashboard/components/TableTitle/TableTitle';
import {UserMetrics} from './_UserMetrics';
import {
  PointsChart,
  PointsChartProps,
} from '../../components/dashboard/common/PointsChart';
import {AllSummaryCourses} from '../../components/courses/user/SummaryCourse/AllSummaryCourses';
import {useTranslation} from 'react-i18next';
import i18n from 'i18n';
import {CustomTrophiesBadgeComponent} from './components/CustomTrophiesBadgeComponent/CustomTrophiesBadgeComponent';
import {ChartTitleAndWeekSelectionWrapper} from 'pages/dashboard/components/ChartTitleAndWeekSelectionWrapper/ChartTitleAndWeekSelectionWrapper';
import {BreadcrumbsStyle} from './components/StyledBreadcrumbs/styles/BreadcrumbsStyle';
import {StyledWelcome} from './components/StyledWelcome/StyledWelcome';
import {User} from './components/User/User';
import {Level} from './components/Level/Level';
import {DetailsGroup} from './components/DetailsGroup/DetailsGroup';
import {Name} from './components/Name/Name';
import {Details} from './components/Details/Details';
import {SectionWrapper} from 'pages/dashboard/components/SectionWrapper/SectionWrapper';
import {ChartWrapper} from 'pages/dashboard/components/ChartWrapper/ChartWrapper';
import {ChartTitle} from 'pages/dashboard/components/ChartTitle/ChartTitle';
import {StyledBreadcrumbs} from './components/StyledBreadcrumbs/StyledBreadcrumbs';
import {Department} from './components/Department/Department';
import {ChartFooter} from 'pages/dashboard/components/ChartFooter/ChartFooter';
import {BulletPoint} from 'pages/dashboard/components/BulletPoint/BulletPoint';

export const UserProfile = () => {
  const {getCurrentWeeksOfYear} = useCurrentWeeksOfYear();
  const weeks = getCurrentWeeksOfYear();
  const initialWeek =
    weeks.length > 0 ? weeks[weeks.length - 1].value : {from: '', to: ''};
  const [{from, to}, setSelectedWeek] = useState(initialWeek);

  const {
    state: {departmentId},
  } = useLocation();
  const {userId} = useParams();
  const {t} = useTranslation('UserProfile');

  const handleWeekChange = (direction: number) => {
    const currentWeekIndex = weeks.findIndex(
      week => week.value.from === from && week.value.to === to
    );

    if (currentWeekIndex === -1) return;

    const newWeekIndex = currentWeekIndex + direction;

    if (newWeekIndex >= 0 && newWeekIndex < weeks.length) {
      const newWeek = weeks[newWeekIndex].value;
      setSelectedWeek(newWeek); // Update the selected week range
    }
  };

  const {data: userProfile} = useUserOverview({
    departmentId: Number(departmentId),
    userId: Number(userId),
    from,
    to,
  });

  const {
    assignedCourses,
    completedCourses,
    points,
    departmentName,
    name,
    avatar,
    level,
    pointsPerDay,
    awards,
    trophyPoints,
    weeklyPoints,
    hasWeeklyTrophy,
  } = userProfile?.data || {};

  const maxVoluntaryPoints = Math.max(
    ...Object.values(pointsPerDay ?? {}).map(day => day.voluntaryPoints)
  );

  const leftScalePoints = maxVoluntaryPoints > 50 ? maxVoluntaryPoints : 50;

  const pointsDetails: PointsChartProps[] = Object.entries(
    userProfile?.data?.pointsPerDay || {}
  )?.map(([day, {trophyPoints, voluntaryPoints}]) => ({
    day: capitalizeFirstLetter(day.substring(0, 3)),
    trophyPoints: trophyPoints,
    voluntaryPoints: voluntaryPoints,
  }));

  const breadCrumbItems = [
    {title: 'Dashboard', href: '/'},
    {
      title: `${i18n.language === 'en' ? `${name ?? ''}'s` : name} ${t(
        'overview'
      )}`,
    },
  ].map((item, index) => (
    <Anchor key={index} href={item?.href}>
      {item.title}
    </Anchor>
  ));

  return (
    <BasePage>
      <StyledWelcome />
      <MainContainer style={{marginTop: '-130px'}}>
        <StyledBreadcrumbs
          separator={<ArrowRight size={16} strokeWidth={1.5} />}
          styles={BreadcrumbsStyle}
        >
          {breadCrumbItems}
        </StyledBreadcrumbs>
        <User>
          <Avatar
            style={{border: '2px solid white'}}
            radius={100}
            src={avatar ?? '/images/neutral-avatar.png'}
            size={155}
          />
          <DetailsGroup>
            <Name>{name}</Name>
            <Details>
              <Level> {level}</Level>
              <Department>
                <span>&#8226;</span>
                {departmentName}
              </Department>
            </Details>
          </DetailsGroup>
        </User>
        <SectionWrapper>
          <UserMetrics
            awards={awards}
            assignedCoursesLength={assignedCourses?.length || 0}
            completedCourses={completedCourses}
            points={points}
            trophyPoints={trophyPoints}
            weeklyPoints={weeklyPoints}
            fullCompletedCourses={userProfile?.data.feedbackCourses}
          />
          <ChartWrapper>
            <ChartTitleAndWeekSelectionWrapper>
              <ChartHeader>
                <ChartTitle>{`${t('UserProfile:chart.title')}`}</ChartTitle>
              </ChartHeader>
              <Button.Group style={{display: 'flex', gap: 2}}>
                <ActionIcon
                  style={{
                    zIndex: 1,
                  }}
                  onClick={() => handleWeekChange(-1)}
                  className="left"
                  size={37}
                  variant="default"
                >
                  <ArrowLeftIcon />
                </ActionIcon>
                <Select
                  style={{
                    zIndex: 999,
                  }}
                  placeholder="Choose a week"
                  data={weeks.map(week => ({
                    value: JSON.stringify(week.value),
                    label: week.label,
                  }))}
                  value={JSON.stringify({from, to})}
                  onChange={value => setSelectedWeek(JSON.parse(String(value)))}
                  dropdownPosition="bottom"
                  withinPortal
                  styles={{
                    wrapper: {
                      alignSelf: 'flex-end',
                    },
                    item: {
                      '&[data-selected], &[data-hovered]': {
                        backgroundColor: Color.GRAY_50,
                        color: Color.DARK,
                      },
                      '&[data-selected]': {
                        '&:hover': {
                          backgroundColor: Color.GRAY_50,
                        },
                      },
                    },
                  }}
                />
                <ActionIcon
                  style={{
                    zIndex: 1,
                  }}
                  onClick={() => handleWeekChange(1)}
                  className="right"
                  size={37}
                  variant="default"
                >
                  <ArrowRightIcon />
                </ActionIcon>
              </Button.Group>
            </ChartTitleAndWeekSelectionWrapper>
            <PointsChart
              maxPointsValue={leftScalePoints}
              pointsDetails={pointsDetails}
            />
            <ChartFooter>
              <div style={{display: 'flex', gap: '1rem'}}>
                <div>
                  <BulletPoint backgroundColor={Color.GREEN_050} />{' '}
                  <span>{`${t('UserProfile:chart.trophyPoints')}`}</span>
                </div>
                <div>
                  <BulletPoint backgroundColor={Color.PURPLE_TXT} />{' '}
                  <span> {`${t('UserProfile:chart.voluntaryPoints')}`}</span>
                </div>
              </div>
              <div>
                {hasWeeklyTrophy && (
                  <CustomTrophiesBadgeComponent
                    displayText={`1 ${t('Dashboard:charts.numberOfTrophies')}`}
                  />
                )}
              </div>
            </ChartFooter>
          </ChartWrapper>
        </SectionWrapper>

        <TableTitle>{t('Feedback:feedback')}</TableTitle>
        <FeedbackCard
          feedbackCourses={userProfile?.data.feedbackCourses || []}
          feedbackNavigation={`/dashboard/feedback/userId/${userId}`}
        />

        <AllSummaryCourses
          courses={userProfile?.data.assignedCourses || []}
          topics={userProfile?.data.topics || []}
        />
        <Space h={50} />
      </MainContainer>
    </BasePage>
  );
};
