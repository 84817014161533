export const getPaginationOptions = () => {
  return [
    {value: '15', label: '15'},
    {value: '30', label: '30'},
    {value: '45', label: '45'},
    {value: '60', label: '60'},
    {value: '75', label: '75'},
    {value: '100', label: '100'},
  ];
};
