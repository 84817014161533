import {LearningProgress} from '../../enums/learning-progress';
import {ApiDataResponse} from '../../types/api/ApiResponse';
import {StartExerciseResponse} from '../../types/manager-tryout-course/StartExerciseResponse';
import {createAxiosInstance} from '../config';

const axios = createAxiosInstance();

export const startManagerTestExercise = async (
  exerciseId: number | undefined
) => {
  const {data} = await axios.get<ApiDataResponse<StartExerciseResponse>>(
    `/${LearningProgress.LEARNING_PROGRESS}/test-exercise/${exerciseId}`
  );
  console.log(data.data);
  return data.data;
};
