import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
} from 'victory';

export interface PointsChartProps {
  day: string;
  trophyPoints: number;
  voluntaryPoints: number;
}

export const PointsChart: FC<{
  pointsDetails: PointsChartProps[];
  maxPointsValue: number;
}> = ({pointsDetails, maxPointsValue}) => {
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const {t} = useTranslation('Dashboard');
  const translatedWeekdays = weekdays.map(day => t(`charts.${day}`));
  const maxTwoDigitsDisplayableNumber = 90;

  const generateFixedPointValues = (maxValue: number): number[] => {
    const magnitude = Math.floor(Math.log10(maxValue));
    const scaleFactor = 10 ** magnitude;
    let step = scaleFactor / 10;
    if (step < 10) step = 10;

    const roundedMax = Math.ceil(maxValue / step) * step;

    const numTicks = 10;
    const adjustedStep = Math.ceil(roundedMax / numTicks / step) * step;

    return Array.from(
      {length: Math.ceil(roundedMax / adjustedStep) + 1},
      (_, i) => i * adjustedStep
    );
  };

  const fixedPointValues = generateFixedPointValues(maxPointsValue);

  return (
    <VictoryChart
      domainPadding={{x: [20, 0]}}
      style={{
        parent: {
          borderRadius: '4px',
          zIndex: 0,
          marginTop: '-50px',
          width: '110%',
        },
      }}
      containerComponent={<VictoryContainer />}
    >
      {/* X-Axis: Weekdays */}
      <VictoryAxis
        tickValues={weekdays}
        tickFormat={x => translatedWeekdays[weekdays.indexOf(x)]}
        style={{
          axis: {stroke: '#E5E5EF'},
          ticks: {size: 5, stroke: 'transparent'},
          tickLabels: {
            fontSize: 10,
            fill: '#A29FC4',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
          },
        }}
      />

      {/* Y-Axis: Points */}
      <VictoryAxis
        dependentAxis
        tickValues={fixedPointValues}
        tickFormat={y => `${y} ${t('charts.points')}`}
        style={{
          axis: {stroke: 'transparent'},
          ticks: {size: 5, stroke: 'transparent'},
          tickLabels: {
            fontSize: 8,
            fill: '#A29FC4',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            padding:
              maxPointsValue > maxTwoDigitsDisplayableNumber ? 2 : undefined,
          },
        }}
      />

      {/* Horizontal Grid Lines */}
      {fixedPointValues.map(fixedPoints => (
        <VictoryLine
          key={fixedPoints}
          data={[
            {x: 'Mon', y: fixedPoints},
            {x: 'Sun', y: fixedPoints},
          ]}
          style={{
            data: {
              stroke: '#E5E5EF',
              strokeWidth: 1,
              strokeDasharray: '3,3',
            },
          }}
        />
      ))}

      {/* Bars */}
      <VictoryGroup>
        <VictoryBar
          data={pointsDetails?.map(({day, trophyPoints}) => ({
            x: day,
            y: trophyPoints,
          }))}
          style={{data: {fill: '#349d8e'}}}
          cornerRadius={{
            bottomLeft: 4,
            bottomRight: 4,
            topLeft: 4,
            topRight: 4,
          }}
          barWidth={25}
        />
        <VictoryBar
          data={pointsDetails?.map(({day, voluntaryPoints}) => ({
            x: day,
            y: voluntaryPoints,
          }))}
          style={{data: {fill: '#bb6bd9'}}}
          cornerRadius={{
            bottomLeft: 4,
            bottomRight: 4,
            topLeft: 4,
            topRight: 4,
          }}
        />
      </VictoryGroup>
    </VictoryChart>
  );
};
