import {TFunction} from 'i18next';
import React from 'react';
import {Column} from '../../../../components/table/DynamicTable';
import {DepartmentUser} from '../../../../types/users/users';

export const getUnassignedEmployeesTableColumns = (
  t: TFunction
): Column<DepartmentUser>[] => {
  return [
    {
      slug: 'person',
      title: {name: t('assignCourse.person'), sortable: false},
      key: 'person',
      render: ({userName}: DepartmentUser) => <span>{userName}</span>,
    },
    {
      slug: 'team',
      title: {name: t('assignCourse.team'), sortable: false},
      key: 'team',
      render: ({departments}: DepartmentUser) => (
        <span>{departments?.map(({name}) => name).join(', ') || ''}</span>
      ),
    },
  ];
};
