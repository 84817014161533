import {Box, Avatar, Badge} from '@mantine/core';
import {TFunction} from 'i18next';
import {Column} from 'components/table/DynamicTable';
import styled from 'styled-components';
import {TeamEmployee} from 'types/users/users';
import React from 'react';
import {Color} from '../../../../enums/common';

export const PointsSpan = styled.div`
  color: #776aa1;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
`;

export const UserNameSpan = styled.span`
  color: ${Color.DARK};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const getTeamEmployeeColumns = (
  t: TFunction,
  currentUserName: string | undefined
): Column<TeamEmployee>[] => [
  {
    slug: 'name',
    title: {name: t('table.teamMember'), sortable: true},
    key: 'name',
    render: ({name, avatar}: TeamEmployee) => (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '.5rem',
        }}
      >
        <Avatar src={avatar} size="md" radius="lg" alt="User profile image" />
        {name ?? 'N/A'}
        {name === currentUserName && (
          <Badge
            style={{
              background: 'rgba(74, 201, 155, 0.44)',
              textTransform: 'none',
            }}
          >
            <UserNameSpan>{t('table.you') as string}</UserNameSpan>
          </Badge>
        )}
      </Box>
    ),
    customColumnColor: ({name}: TeamEmployee) =>
      currentUserName === name ? `${Color.PURPLE_500}` : undefined,
  },
  {
    slug: 'awards',
    title: {name: t('inputs.thisWeek'), sortable: true},
    key: 'weeklyPoints',
    render: ({weeklyAwards, weeklyPoints}: TeamEmployee) => (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '.5rem',
        }}
      >
        <span>🏆{weeklyAwards}</span>
        {weeklyPoints > 0 && (
          <PointsSpan>{`${weeklyPoints} ${t('charts.points')}`}</PointsSpan>
        )}
      </Box>
    ),
    customColumnColor: ({name}: TeamEmployee) =>
      currentUserName === name ? `${Color.PURPLE_500}` : undefined,
  },
  {
    slug: 'pointsOverview',
    title: {name: t('awards'), sortable: true},
    key: 'awards',
    render: ({awards}: TeamEmployee) => <span>🏆{awards}</span>,
    customColumnColor: ({name}: TeamEmployee) =>
      currentUserName === name ? `${Color.PURPLE_500}` : undefined,
  },
];
