import React, {FC, useContext} from 'react';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {Breadcrumbs, Loader, Space, Tabs} from '@mantine/core';
import styled from 'styled-components';

// Components
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import {RichTextContent} from 'components/RichText';

// Types
import {Color} from 'enums/common';
import {useCourseFeedback} from 'api/tanstack-hooks/users/useGetCourseFeedbacks';
import {CourseFeedback} from 'types/users/users';
import {ArrowRight} from 'tabler-icons-react';
import {NoDataPlaceholder} from '../../components/common';
import {UserContext} from 'contexes';
import {Role} from 'enums/auth';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {formatDate} from 'utils/common/datesUtil';
import 'dayjs/locale/nb';
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import {ExpandableDatePicker} from '../../components/ExpandableDatePicker/ExpandableDatePicker';
import {getLocale} from '../../components/ExpandableDatePicker/utils/ExpandableDatePickerUtils';
import useLocalAndUrlStore from '../../store/useLocalAndUrlStore';

// Styled components
const StyledWelcome = styled.div`
  background-color: ${Color.DARK};
  height: 230px;
`;

const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 350px);
`;

const Wrapper = styled.div`
  background-color: ${Color.WHITE};
  margin-top: -200px;
  min-height: calc(100vh - 110px);
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserName = styled.div`
  color: ${Color.DARK};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-transform: capitalize;
`;

const Title = styled.div`
  color: ${Color.DARK};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
  padding: 16px 0;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding-bottom: 10px;

  color: ${Color.DARK};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  & div {
    margin: 6px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
`;

const BreadcrumbsStyle = {
  breadcrumb: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 500,
    color: Color.DARK,
  },
  separator: {color: Color.DARK, fontSize: '12px', fontWeight: 500},
};

const CalendarWrapper = styled.div`
  align-self: flex-end;

  & input {
    height: 36px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.DARK};
    width: 300px;
  }
`;

Tabs.Panel;

const StyledPanel = styled(Tabs.Panel)`
  padding-left: 24px;
`;

const PanelTitle = styled.div`
  color: ${Color.DARK};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 24px;
  letter-spacing: 0.15px;
`;

const StyledTab = styled(Tabs.Tab)`
  color: ${Color.DARK};
  min-width: 200px;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;

  &[aria-selected='true'] {
    border-color: ${Color.DARK} !important;
    color: ${Color.DARK};
    background-color: #e7e7f8 !important;
  }

  &:focus,
  :active {
    border-color: ${Color.DARK} !important;
    color: ${Color.DARK};
    background-color: #e7e7f8 !important;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateDisplayStyledSpan = styled.span`
  color: ${Color.VIOLET_VIOLET4};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const Feedback: FC = () => {
  const {setFrom, setTo, from, to} = useLocalAndUrlStore();

  // Hooks
  const {userId} = useParams();

  const [userContext] = useContext(UserContext);
  const {t} = useTranslation('Feedback');

  const isManager = userContext.authority.includes(Role.MANAGER);

  const [searchParams] = useSearchParams();
  const courseId = searchParams.get('courseId');

  // Fetch course feedback data
  const {data: feedbackData, isLoading} = useCourseFeedback({
    id: Number(userId),
    from: formatDate(from),
    to: formatDate(to),
  });

  const breadCrumbItems = [
    {title: 'Dashboard', to: '/'},
    ...(isManager
      ? [
          {
            title: feedbackData?.data?.userName || 'User Profile',
          },
          {title: `${t('feedback')}`},
        ]
      : [{title: 'yourFeedback', to: '#'}]),
  ].map((item, index) => (
    <Link key={index} to={item?.to || ''}>
      {item.title}
    </Link>
  ));

  const parseFeedbackGenerationTimestamp = (timestamp: Date) => {
    return new Date(timestamp).toLocaleString(getLocale(), {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  return (
    <BasePage>
      <StyledWelcome />
      <MainContainer>
        <Wrapper>
          <Header>
            <div>
              <StyledBreadcrumbs
                separator={<ArrowRight size={16} strokeWidth={1.5} />}
                styles={BreadcrumbsStyle}
              >
                {breadCrumbItems}
              </StyledBreadcrumbs>
              {feedbackData?.data.userName && (
                <UserName>
                  {isManager
                    ? `${feedbackData?.data?.userName.trim()}${
                        feedbackData?.data?.userName.trim().endsWith('s')
                          ? "'"
                          : "'s"
                      } ${t('feedback')}`
                    : `${t('yourFeedback')}`}
                </UserName>
              )}
            </div>
            <CalendarWrapper>
              <ExpandableDatePicker
                setFrom={setFrom}
                setTo={setTo}
                placeholder={t('chooseDateRange')}
                initialRange={[
                  dayjs().startOf('week').add(1, 'day').toDate(),
                  dayjs().endOf('week').add(1, 'day').toDate(),
                ]}
                disabled={
                  !feedbackData?.data?.feedbacksPerCourse?.length || isLoading
                }
              />
            </CalendarWrapper>
          </Header>
          <Title>{`${t('completedCourses')}`}</Title>
          {isLoading && (
            <LoaderWrapper>
              <Loader size="lg" />
            </LoaderWrapper>
          )}
          {!isLoading &&
            (feedbackData &&
            feedbackData?.data?.feedbacksPerCourse?.length > 0 ? (
              <Tabs
                defaultValue={
                  feedbackData?.data?.feedbacksPerCourse.find(
                    course => course.courseId === Number(courseId)
                  )?.courseName ||
                  feedbackData?.data?.feedbacksPerCourse[0]?.courseName
                }
                orientation="vertical"
              >
                <Tabs.List>
                  {feedbackData?.data?.feedbacksPerCourse?.map?.(
                    (course: CourseFeedback) => (
                      <StyledTab
                        key={course.courseName}
                        value={course.courseName}
                      >
                        {course.courseName}
                      </StyledTab>
                    )
                  )}
                </Tabs.List>
                {feedbackData?.data?.feedbacksPerCourse?.map?.(
                  (course: CourseFeedback) => (
                    <StyledPanel
                      key={course.courseName}
                      value={course.courseName}
                    >
                      {course.exerciseFeedbacks.length > 0 ? (
                        course.exerciseFeedbacks.map?.(exercise => (
                          <div key={exercise.exerciseName}>
                            <PanelTitle>{course.courseName}</PanelTitle>
                            <ul>
                              {exercise?.feedbacks?.map?.((feedback, index) => (
                                <li key={index}>
                                  {' '}
                                  <RichTextContent
                                    htmlContent={feedback.feedback}
                                  />{' '}
                                  <DateDisplayStyledSpan>
                                    {parseFeedbackGenerationTimestamp(
                                      feedback.timestamp
                                    )}
                                  </DateDisplayStyledSpan>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))
                      ) : (
                        <div>
                          <PanelTitle>{course.courseName}</PanelTitle>
                          <NoDataPlaceholder
                            buttonText={`${t('goToCourse')}`}
                            title={isManager ? '' : `${t('noFeedbackYet')}`}
                            imageSrc="/images/noFeedbackTab.png"
                            content={`${t('feedbackNoDataTitle')}`}
                            to={
                              isManager
                                ? ''
                                : `/manager/course/${course.userCourseId}/assign`
                            }
                          />
                        </div>
                      )}
                    </StyledPanel>
                  )
                )}
              </Tabs>
            ) : (
              <NoDataWrapper>
                <NoDataPlaceholder
                  align="center"
                  imageSrc="/images/noFeedbackTab.png"
                  title={`${t('courseFeedbackNoDataTitle')}`}
                  content={`${t('courseFeedbackNoDataContent')}`}
                />
              </NoDataWrapper>
            ))}
          <Space h={35} />
        </Wrapper>
      </MainContainer>
    </BasePage>
  );
};
