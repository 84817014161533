import React, {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

// Components
import {Button, Space} from '@mantine/core';
import {
  SlideContent,
  SlideContentHeader,
  SlideFooter,
} from 'components/slideText';
import {Text} from 'components/common';

// Styles
import {DarkButtonStyle} from 'styles/buttons';
import {Color} from 'enums/common';
import {CourseContext} from '../../../../pages/courses/Course';
import ChatbotV3Analyses from './ChatbotV3Analyses';
import {useChatBotV3Store} from 'store/ChatBotV3store';

interface Analyses {
  conversationId: number;
  feedback: string;
}

export interface Feedback {
  analyses: Analyses[];
}

interface Props {
  feedbackLoading: boolean;
  handleRetry: () => void;
  handleEndExercise: () => void;
  handleShowingConversationTranscription?: () => void;
}

const ChatBotV3Feedback = ({
  feedbackLoading,
  handleRetry,
  handleEndExercise,
  handleShowingConversationTranscription,
}: Props) => {
  const {t} = useTranslation('Practices');

  const {setShowConversationTranscription} = useContext(CourseContext);

  const {conversationFeedback} = useChatBotV3Store();

  useEffect(() => {
    handleShowingConversationTranscription?.();
    setShowConversationTranscription?.(true);
    return () => {
      setShowConversationTranscription?.(false);
    };
  }, [
    handleShowingConversationTranscription,
    setShowConversationTranscription,
  ]);

  return (
    <div style={{height: 'calc(100vh - 250px)'}}>
      <SlideContent style={{height: '100%'}}>
        <SlideContentHeader>
          <Text fontSize={18} fontWeight={700} color={Color.DARK}>
            {t('feedback')}
          </Text>
        </SlideContentHeader>
        <ChatbotV3Analyses
          feedback={conversationFeedback}
          title={t('voicebot.feedbackTitle')}
          loading={feedbackLoading}
        />
      </SlideContent>
      <SlideFooter>
        <Button
          size="md"
          style={{
            ...DarkButtonStyle,
            opacity: feedbackLoading ? '50%' : '100%',
          }}
          onClick={handleRetry}
          disabled={feedbackLoading}
        >
          {t('voicebot.retry')}
        </Button>
        <Space w={10} />

        <Button
          size="md"
          style={{
            fontFamily: 'Montserrat',
            fontSize: 14,
            fontWeight: 700,
            color: Color.WHITE,
            backgroundColor: Color.GREEN_050,
            borderRadius: 0,
            opacity: feedbackLoading ? '50%' : '100%',
          }}
          disabled={feedbackLoading}
          onClick={() => handleEndExercise()}
        >
          {t('voicebot.endExercise')}
        </Button>
      </SlideFooter>
    </div>
  );
};

export default ChatBotV3Feedback;
