import {useQuery} from '@tanstack/react-query';
import {getDepartmentUsers} from 'api/department/department';

export const useGetDepartmentUsers = (
  courseId: number,
  departmentId: number | null
) => {
  return useQuery(['department/users', courseId, departmentId], () =>
    getDepartmentUsers(courseId, departmentId)
  );
};
