import {Box, Avatar, Anchor, Flex, Button} from '@mantine/core';
import {Plus} from 'tabler-icons-react';
import {format} from 'date-fns';
import {useNavigate} from 'react-router-dom';
import {TFunction} from 'i18next';
import {OpenInNew} from '../../../../components/icons/Dynamic Table/OpenInNew';
import {Column} from '../../../../components/table/DynamicTable';
import {TeamEmployee} from 'types/users/users';
import React from 'react';
import {capitalizeFirstLetter} from 'utils/common';
import {TrophiesSpan} from 'pages/dashboard/components/TrophiesSpan/TrophiesSpan';
import {Color} from '../../../../enums/common';
import {AssignedCoursesSpan} from '../../components/AssignedCoursesSpan/AssignedCoursesSpan';
import {PointsSpan} from '../../components/PointsSpan/PointsSpan';

export const getManagerUsersTableColumns = (
  t: TFunction,
  setUserId: (id: number) => void,
  setOpenModal: (open: boolean) => void,
  setUserName: (name: string) => void,
  departmentId: string | null
): Column<TeamEmployee>[] => {
  const navigate = useNavigate();

  return [
    {
      slug: 'name',
      title: {name: t('table.teamMember'), sortable: true},
      key: 'name',
      render: ({avatar, name, email, id}: TeamEmployee) => (
        <Box style={{display: 'flex', alignItems: 'center', gap: '.5rem'}}>
          <Avatar
            onClick={() =>
              navigate(`userProfile/userId/${id}`, {state: {departmentId}})
            }
            src={avatar}
            size="md"
            radius="lg"
            alt="User profile image"
          />
          <Anchor
            style={{pointerEvents: name !== null ? 'auto' : 'none'}}
            onClick={() =>
              navigate(`userProfile/userId/${id}`, {state: {departmentId}})
            }
          >
            {name ?? email ?? '-'}
          </Anchor>
        </Box>
      ),
    },
    {
      slug: 'level',
      title: {name: t('table.level'), sortable: true},
      key: 'level',
      render: ({level}: TeamEmployee) =>
        capitalizeFirstLetter(t(`courseCards.${level.toLowerCase()}`)),
    },
    {
      slug: 'awards',
      title: {name: t('inputs.thisWeek'), sortable: true},
      key: 'awards',
      render: ({weeklyAwards, weeklyPoints}: TeamEmployee) => (
        <Box style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
          <TrophiesSpan>🏆{weeklyAwards}</TrophiesSpan>
          <PointsSpan>{`${weeklyPoints} ${t('charts.points')}`}</PointsSpan>
        </Box>
      ),
    },
    {
      slug: 'lastLoginDate',
      title: {name: t('table.lastActivity'), sortable: true},
      key: 'lastActivity',
      render: ({lastActivity, state}: TeamEmployee) => (
        <>
          {state === 'PENDING' ? (
            <span style={{color: 'red'}}>{`${t(
              'table.pendingInvitation'
            )}`}</span>
          ) : lastActivity ? (
            format(new Date(lastActivity), 'dd/MM/yyyy')
          ) : (
            <span style={{color: 'red'}}>{`${t('table.notActive')}`}</span>
          )}
        </>
      ),
    },
    {
      slug: 'points',
      title: {name: t('table.points'), sortable: true},
      key: 'points',
      render: ({points, trophyPoints}: TeamEmployee) => (
        <Box style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
          <TrophiesSpan>🏆{trophyPoints}</TrophiesSpan>
          <PointsSpan>{`${points} ${t('charts.points')}`}</PointsSpan>
        </Box>
      ),
    },
    {
      slug: 'feedbackReport',
      title: {name: t('table.feedbackReport'), sortable: false},
      key: 'feedbackReport',
      render: ({name, id, state}: TeamEmployee) => {
        if (state === 'PENDING') {
          return <></>;
        }
        return (
          <Anchor
            style={{
              color: Color.GREEN_050,
              textDecoration: 'underline',
              fontSize: '14px',
              fontWeight: 600,
            }}
            component="button"
            type="button"
            onClick={() => navigate(`feedback/userId/${id}`, {state: name})}
          >
            <Flex align="center" gap="3px">
              <span>{`${t('table.viewFeedback')}`}</span>
              <OpenInNew />
            </Flex>
          </Anchor>
        );
      },
    },
    {
      slug: 'assignedCourses',
      title: {name: t('table.assignedCourses'), sortable: true},
      key: 'assignedCourses',
      render: ({assignedCourses}: TeamEmployee) => (
        <AssignedCoursesSpan>{assignedCourses}</AssignedCoursesSpan>
      ),
    },
    {
      slug: 'action',
      title: {name: t('table.action'), sortable: false},
      key: 'action',
      render: ({name, id}: TeamEmployee) => (
        <Button
          style={{
            background: '#EDE7FF',
            color: '#26145C',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 400,
          }}
          leftIcon={<Plus />}
          onClick={() => {
            setUserId(id);
            setOpenModal(true);
            setUserName(name);
          }}
        >
          <span>{`${t('table.enroll')}`}</span>
        </Button>
      ),
    },
  ];
};
