import {Chapter} from '.';
import {Category} from '../category/category';
import {Organization} from '../organizations/organizations';

interface ContinueCoursePosition {
  chapterPosition: number;
  pagePosition: number;
}
export interface AssignedCourse {
  category: Category;
  chapterLearningTime: number;
  completeChapterNum: number;
  courseId: number;
  continueCoursePosition: ContinueCoursePosition;
  courseName: string;
  courseSubtitle?: string;
  coverImage: string;
  createDate: string;
  learningPercent: number;
  organization: Organization;
  skills: string[];
  level: Level;
  timesCompleted: number;
  lastActivity: string;
  summary: string;
  totalChaptersNum: number;
  totalChapterNum: number;
  totalLearnTime: number;
  userCourseId: number;
  points: number | null;
  type: CourseCategory[];
}

export interface EmployeeCourse {
  activationStatus: ActivationStatus;
  chapters: Chapter[];
  category: Category;
  isRecommended: boolean;
  duration: number;
  organization: Organization;
  subtitle?: string;
  id: number;
  imageId: string;
  imageUrl: string;
  isLeadership: boolean;
  isPublic: boolean;
  level: Level;
  name: string;
  skills: string;
  status: Level;
  summary: string;
  timeScore: number;
  timesCompleted: number;
  assignedExercises: number;
  completedExercises: number;
}

export interface DepartmentProgress {
  id: number;
  imageUrl: string;
  learningTime: number;
  name: string;
  onGoingCourses: number;
  points: number;
  completedCourses: number;
  inProgressCourses: number;
  notStartedCourses: number;
}

export interface WorkerProgres {
  id: number;
  learningTime: number;
  name: string;
  onGoingCourses: number;
  points: number;
  completedExercises: number;
  completedCourses: number;
  assignedExercises: number;
  assignedCourses: number;
  totalLearningTime: number;
}

export interface ActivationStatus {
  id: number;
  name: ActivationStatusName;
}

export enum ActivationStatusName {
  Approved = 'Approved',
  New = 'New',
}

export interface Level {
  id: number;
  name: LevelName;
}

export enum LevelName {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Expert = 'Expert',
}

export interface Status {
  id: number;
  name: StatusName;
}

export enum StatusName {
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
}

export enum CourseType {
  VIDEO = 2,
  EXERCISE = 1,
  NONE = 0,
  BOTH = 3,
}

export interface AssignableCourse {
  id: string;
  name: string;
}
export interface CourseCategory {
  id: number;
  name: string;
}
