import {DatePickerInput} from '@mantine/dates';
import dayjs from 'dayjs';
import {Color} from 'enums/common';
import React, {useEffect, useState} from 'react';
import {CalendarEvent} from 'tabler-icons-react';
import {ExpandableDatePickerProps} from './interfaces/ExpandableDatePickerProps';
import {getLocale} from './utils/ExpandableDatePickerUtils';

export const ExpandableDatePicker = ({
  setFrom,
  setTo,
  initialRange = [
    dayjs().startOf('week').add(1, 'day').toDate(),
    dayjs().endOf('week').add(1, 'day').toDate(),
  ],
  placeholder = '',
  maxDate = dayjs().add(1, 'year').toDate(),
  disabled,
}: ExpandableDatePickerProps) => {
  const [dateRange, setDateRange] =
    useState<[Date | null, Date | null]>(initialRange);

  useEffect(() => {
    setFrom(dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : null);
    setTo(dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : null);
  }, [dateRange, setFrom, setTo]);

  return (
    <DatePickerInput
      rightSection={<CalendarEvent style={{color: '#776AA1'}} />}
      locale={getLocale()}
      type="range"
      placeholder={placeholder}
      value={dateRange}
      onChange={(newValue: [Date | null, Date | null]) =>
        setDateRange(newValue || [null, null])
      }
      disabled={disabled}
      styles={{
        input: {
          width: 300,
          color: Color.DARK,
        },
        day: {
          '&[data-in-range]': {
            backgroundColor: Color.GRAY_50,
          },
          '&[data-selected]': {
            backgroundColor: Color.DARK,
          },
        },
      }}
      maxDate={maxDate}
    />
  );
};
