import dayjs from 'dayjs';
import React, {useState} from 'react';
import {Space, Avatar, Select, Button, ActionIcon} from '@mantine/core';

// Components
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import FeedbackCard from 'components/common/FeedbackCard/FeedbackCard';
import {UserMetrics} from 'pages/UserProfile/_UserMetrics';
import {
  PointsChart,
  PointsChartProps,
} from 'components/dashboard/common/PointsChart';
import {AllSummaryCourses} from 'components/courses/user/SummaryCourse/AllSummaryCourses';
import {DynamicTable} from 'components/table/DynamicTable';

// Types
import {Color} from 'enums/common';

// Utils
import {useCurrentWeeksOfYear} from 'utils/common/datesUtil';
import {capitalizeFirstLetter} from 'utils/common';

// Hooks
import {useUserDashboard} from 'api/tanstack-hooks/users/useUserDashboard';
import {useUserTeamOverview} from 'api/tanstack-hooks/department/useUserTeamOverview';
import {useTranslation} from 'react-i18next';
import {DynamicPagination} from '../../../components/DynamicPagination/DynamicPagination';
import {getPaginationOptions} from '../../../components/table/table-config/pagination-options';
import {CustomTrophiesBadgeComponent} from '../../UserProfile/components/CustomTrophiesBadgeComponent/CustomTrophiesBadgeComponent';
import {BulletPoint} from '../components/BulletPoint/BulletPoint';
import {ChartFooter} from '../components/ChartFooter/ChartFooter';
import {ChartHeader} from '../components/ChartHeader/ChartHeader';
import {ChartTitle} from '../components/ChartTitle/ChartTitle';
import {ChartTitleAndWeekSelectionWrapper} from '../components/ChartTitleAndWeekSelectionWrapper/ChartTitleAndWeekSelectionWrapper';
import {ArrowLeftIcon} from '../components/ChartTitleAndWeekSelectionWrapper/icon-components/ArrowLeftIcon';
import {ArrowRightIcon} from '../components/ChartTitleAndWeekSelectionWrapper/icon-components/ArrowRightIcon';
import {ChartWrapper} from '../components/ChartWrapper/ChartWrapper';
import {Header} from '../components/Header/Header';
import {PaginationWrapper} from '../components/PaginationWrapper/PaginationWrapper';
import {StyledWelcome} from '../components/StyledWelcome/StyledWelcome';
import {TableTitle} from '../components/TableTitle/TableTitle';
import {TableTitleAndDatePickerWrapper} from '../components/TableTitleAndDatePickerWrapper/TableTitleAndDatePickerWrapper';
import {Title} from '../components/Title/Title';
import {Subtitle} from '../components/Subtitle/Subtitle';
import {SectionWrapper} from '../components/SectionWrapper/SectionWrapper';
import {getTeamEmployeeColumns} from './table-config/user-dashboard-table-columns';
import {ExpandableDatePicker} from 'components/ExpandableDatePicker/ExpandableDatePicker';

export const UserDashboard = () => {
  // Hooks
  const {t} = useTranslation('Dashboard');
  const {getCurrentWeeksOfYear} = useCurrentWeeksOfYear(t);
  const weeks = getCurrentWeeksOfYear();
  // Initialize state
  const initialWeek = weeks[weeks.length - 1].value;

  // State
  const [sortKey, setSortKey] = useState<string>('');
  const [{from, to}, setSelectedWeek] = useState(initialWeek);
  const [teamOverviewFrom, setTeamOverviewFrom] = useState(from);
  const [teamOverviewTo, setTeamOverviewTo] = useState(to);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(15);

  const handleWeekChange = (direction: number) => {
    const currentWeekIndex = weeks.findIndex(
      week => week.value.from === from && week.value.to === to
    );

    if (currentWeekIndex === -1) return;

    const newWeekIndex = currentWeekIndex + direction;

    if (newWeekIndex >= 0 && newWeekIndex < weeks.length) {
      const newWeek = weeks[newWeekIndex].value;
      setSelectedWeek(newWeek);
    }
  };

  const {data: userTeamOverviewData, isLoading} = useUserTeamOverview({
    page,
    size,
    from: teamOverviewFrom,
    to: teamOverviewTo,
    sortKey,
  });

  const {data: userProfile} = useUserDashboard({
    from,
    to,
  });

  const {
    assignedCourses,
    totalAssignedCourses,
    completedCourses,
    points,
    name,
    email,
    awards,
    trophyPoints,
    pointsPerDay,
    weeklyPoints,
    hasWeeklyTrophy,
  } = userProfile?.data || {};

  const maxVoluntaryPoints = Math.max(
    ...Object.values(pointsPerDay ?? {}).map(day => day.voluntaryPoints)
  );

  const leftScalePoints = maxVoluntaryPoints > 50 ? maxVoluntaryPoints : 50;

  // Time details
  const timeDetails: PointsChartProps[] = Object.entries(
    userProfile?.data?.pointsPerDay || {}
  )?.map(([day, {trophyPoints, voluntaryPoints}]) => ({
    day: capitalizeFirstLetter(day.substring(0, 3)),
    trophyPoints: trophyPoints,
    voluntaryPoints: voluntaryPoints,
  }));

  const totalPages = Math.ceil((userTeamOverviewData?.data?.total || 0) / size);

  return (
    <BasePage>
      <StyledWelcome />
      <MainContainer
        style={{
          marginTop: '-120px',
        }}
      >
        <Header>
          <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <Avatar variant="filled" size="xl" color="blue" radius="50%">
              {name
                ?.split(' ')
                .map(n => n[0])
                .join('')
                .toUpperCase() ?? ''}
            </Avatar>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '.7rem',
                justifyContent: 'center',
              }}
            >
              <Title>{`${t('welcomeBanner.bannerTitle')}, ${
                name ?? email ?? ''
              }!`}</Title>
              <Subtitle>{`${t('endUserSubtitle')}`}</Subtitle>
            </div>
          </div>
        </Header>
        <SectionWrapper>
          <UserMetrics
            assignedCoursesLength={totalAssignedCourses || 0}
            completedCourses={completedCourses}
            points={points}
            awards={awards}
            weeklyPoints={weeklyPoints}
            trophyPoints={trophyPoints}
            fullCompletedCourses={userProfile?.data.feedbackCourses}
          />
          <ChartWrapper>
            <ChartTitleAndWeekSelectionWrapper>
              <ChartHeader>
                <ChartTitle>{`${t('UserProfile:chart.title')}`}</ChartTitle>
              </ChartHeader>
              <Button.Group style={{display: 'flex', gap: 2}}>
                <ActionIcon
                  style={{
                    zIndex: 1,
                  }}
                  onClick={() => handleWeekChange(-1)}
                  className="left"
                  size={37}
                  variant="default"
                >
                  <ArrowLeftIcon />
                </ActionIcon>
                <Select
                  style={{
                    zIndex: 999,
                  }}
                  placeholder="Choose a week"
                  data={weeks.map(week => ({
                    value: JSON.stringify(week.value),
                    label: week.label,
                  }))}
                  value={JSON.stringify({from, to})}
                  onChange={value => setSelectedWeek(JSON.parse(String(value)))}
                  dropdownPosition="bottom"
                  withinPortal
                  styles={{
                    wrapper: {
                      alignSelf: 'flex-end',
                    },
                    item: {
                      '&[data-selected], &[data-hovered]': {
                        backgroundColor: Color.GRAY_50,
                        color: Color.DARK,
                      },
                      '&[data-selected]': {
                        '&:hover': {
                          backgroundColor: Color.GRAY_50,
                        },
                      },
                    },
                  }}
                />
                <ActionIcon
                  style={{
                    zIndex: 1,
                  }}
                  onClick={() => handleWeekChange(1)}
                  className="right"
                  size={37}
                  variant="default"
                >
                  <ArrowRightIcon />
                </ActionIcon>
              </Button.Group>
            </ChartTitleAndWeekSelectionWrapper>
            <PointsChart
              maxPointsValue={leftScalePoints}
              pointsDetails={timeDetails}
            />
            <ChartFooter>
              <div style={{display: 'flex', gap: '1rem'}}>
                <div>
                  <BulletPoint backgroundColor={Color.GREEN_050} />{' '}
                  <span>{`${t('UserProfile:chart.trophyPoints')}`}</span>
                </div>
                <div>
                  <BulletPoint backgroundColor={Color.PURPLE_TXT} />{' '}
                  <span> {`${t('UserProfile:chart.voluntaryPoints')}`}</span>
                </div>
              </div>
              <div>
                {hasWeeklyTrophy && (
                  <CustomTrophiesBadgeComponent
                    displayText={`1 ${t('charts.numberOfTrophies')}`}
                  />
                )}
              </div>
            </ChartFooter>
          </ChartWrapper>
        </SectionWrapper>

        <Space h={'xl'} />

        <TableTitle>{t('Feedback:feedback')}</TableTitle>
        <FeedbackCard
          feedbackCourses={userProfile?.data.feedbackCourses || []}
          feedbackNavigation={`/dashboard/feedback/userId/${
            userProfile?.data.id || ''
          }`}
        />

        <Space h={'xl'} />
        <Space h={'xl'} />

        <TableTitleAndDatePickerWrapper>
          <TableTitle>{t('table.title')}</TableTitle>
          <ExpandableDatePicker
            setFrom={value => setTeamOverviewFrom(value ?? '')}
            setTo={value => setTeamOverviewTo(value ?? '')}
            placeholder={t('chooseDateRange')}
            initialRange={[
              dayjs().startOf('week').add(1, 'day').toDate(),
              dayjs().endOf('week').add(1, 'day').toDate(),
            ]}
          />
        </TableTitleAndDatePickerWrapper>

        <DynamicTable
          isLoading={isLoading}
          data={userTeamOverviewData?.data?.elements || []}
          columns={getTeamEmployeeColumns(t, userProfile?.data?.name)}
          rowsPerPage={size}
          onSort={({key, order}) => {
            if (key && order) {
              setSortKey(`${String(key)},${order.toUpperCase()}`);
            } else {
              setSortKey('');
            }
          }}
        />
        <PaginationWrapper>
          <DynamicPagination
            page={page}
            totalPages={totalPages}
            size={size}
            setSize={setSize}
            setPage={setPage}
            paginationOptions={getPaginationOptions()}
          />
        </PaginationWrapper>
        <AllSummaryCourses courses={assignedCourses || []} hasButton />
        <Space h={50} />
      </MainContainer>
    </BasePage>
  );
};
