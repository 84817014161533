import styled from 'styled-components';

export const TrophiesSpan = styled.div`
  color: #26145c;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
`;
