import {useDisclosure} from '@mantine/hooks';
import React from 'react';
import styled from 'styled-components';

import {Anchor, Box, Breadcrumbs, Button, Modal, Text} from '@mantine/core';

import {Color} from '../../../../enums/common';
import {AssignedCourse} from '../../../../types/courses/courses';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Chapter} from 'types/chapters/chapters';
import {ManagerTryCourse} from '../../manager/ManagerTryCrouse/ManagerTryCourse';
import {CoursePointsPreview} from './CoursePointsPreview/CoursePointsPreview';

const StyledTitle = styled(Text)`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-bottom: 12px;
`;

const CoursePreviewHeaderContainer = styled.div`
  background-color: #e7e7f8;
  height: 300px;
  padding: 15px 20px 0 130px;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = () => {
  return {
    root: {
      fontFamily: 'Montserrat',
      color: Color.WHITE,
      borderRadius: 0,
      backgroundColor: Color.DARK,
      width: '13em',
      height: '2.8em',
    },
    '&:hover': {
      backgroundColor: Color.DARK,
    },
  };
};

const StyledTryOutButton = () => {
  return {
    root: {
      fontFamily: 'Montserrat',
      color: Color.DARK,
      borderRadius: 0,
      backgroundColor: Color.PURPLE_400,
      width: '13em',
      height: '2.8em',
    },
    '&:hover': {
      backgroundColor: Color.DARK,
    },
  };
};

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export interface ManagerTopCourseDetailsProps {
  assignedCourse: AssignedCourse;
  chapters: Chapter[];
}

export const ManagerTopCourseDetails: React.FC<
  ManagerTopCourseDetailsProps
> = ({assignedCourse, chapters}) => {
  const {t} = useTranslation('Courses');
  const [opened, {open, close}] = useDisclosure(false);

  const items = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.courses'),
      href: '/courses',
    },
    {title: `${assignedCourse.courseName}`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  const availablePoints = assignedCourse?.points ?? 0;

  const renderPointsComponent = () => {
    return (
      availablePoints > 0 && (
        <CoursePointsPreview totalPoints={availablePoints} />
      )
    );
  };

  return (
    <CoursePreviewHeaderContainer>
      <Modal
        opened={opened}
        onClose={close}
        fullScreen
        transitionProps={{transition: 'fade', duration: 200}}
        styles={{
          content: {
            backgroundColor: `${Color.GRAY_100}`,
          },
          header: {
            backgroundColor: `${Color.GRAY_100}`,
          },
        }}
      >
        <ManagerTryCourse
          onClose={close}
          course={assignedCourse}
          chapters={chapters}
        />
      </Modal>
      <Box>
        <Breadcrumbs
          style={{paddingBottom: '70px'}}
          separator="→"
          styles={{
            breadcrumb: {
              color: Color.DARK,
              fontSize: '12px',
              fontFamily: 'Montserrat',
            },
            separator: {color: Color.DARK, fontSize: '12px'},
          }}
        >
          {items}
        </Breadcrumbs>
        <StyledTitle>{assignedCourse.courseName}</StyledTitle>
        <ButtonsWrapper>
          <Button
            component={Link}
            to={`/manager/course/${assignedCourse.courseId}/assign`}
            style={StyledButton().root}
          >
            {t('courseDetails.assignToACourse')}
          </Button>
          <Button onClick={open} style={StyledTryOutButton().root}>
            {t('tryItOut')}
          </Button>
        </ButtonsWrapper>
      </Box>
      {renderPointsComponent()}
    </CoursePreviewHeaderContainer>
  );
};
