import React from 'react';

interface VideoIconProps {
  width?: number;
  height?: number;
}

export const VideoIcon = ({width = 18, height = 17}: VideoIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{display: 'block', verticalAlign: 'middle'}}
    >
      <path
        d="M8.83333 6.66667V13.3333L13 10L8.83333 6.66667ZM18 2.5H3V17.5H18V2.5ZM16.3333 15.8333H4.66667V4.16667H16.3333V15.8333Z"
        fill="#26145C"
      />
    </svg>
  );
};
